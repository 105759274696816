import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import { Button } from '../../../../../../../theme';
import OrangeBogieCheckbox from '../../components/orange-bogie-checkbox/orange-bogie-checkbox';
import RailcarType1 from '../../components/railcar-type1/railcar-type1';

const NonOrangeBogiesType1 = ({ formData, handleChange, handleValidate }) => {
	const { t } = useTranslation();
	const type = 'type1';
	return (
		<div className="content-container">
			<RailcarType1 />
			<h3 className="content__subtitle">
				{t('result-sheet:popup.result-form.step-non-orange-bogies')}
			</h3>
			<div className="non-orange-bogies-content">
				<RailcarType1 isNonOrangeBogie />
				<div className="orange-bogie-checkbox">
					{formData.nonOrangeBogies.map((checked, index) => {
						return (
							<OrangeBogieCheckbox
								key={index}
								checked={checked}
								handleChange={handleChange}
								index={index}
								type={type}
							/>
						);
					})}
				</div>
			</div>
			<div className="content__actions">
				<Button
					variant="primary"
					onClick={handleValidate}
					label={t('result-sheet:popup.result-form.validate-to-final-step')}
				/>
			</div>
		</div>
	);
};

export default NonOrangeBogiesType1;

NonOrangeBogiesType1.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
};
