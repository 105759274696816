import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExcelJS from 'exceljs';

import './admin-user-list.scss';

import { useDebouncedAction, useStoredFilter } from '../../../../shared';
import { CdrPage } from '../../../../theme';
import { searchUser } from '../../user.services';

import UserListRow from './components/user-list-row/user-list-row';
import UserListSubHeader from './components/user-list-sub-header/user-list-sub-header';

const filterConfig = {
	storageName: 'adminUserListFilter',
	filter: {
		role: [],
		associatedLine: [],
		search: '',
	},
	sort: {},
};

const AdminUserList = () => {
	const { t } = useTranslation();

	const [userList, setUserList] = useState([]);
	const [loading, setLoading] = useState(false);

	function sortLines(lines) {
		const lineArray = lines.split(', ').map((line) => line.trim());
		const numericLines = lineArray.filter((line) => !isNaN(line)).sort((a, b) => a - b);
		const alphaLines = lineArray.filter((line) => isNaN(line)).sort();
		return [...numericLines, ...alphaLines].join(', ');
	}

	function foundLineByRole(role, associated, main) {
		let allowedRoles = [
			'regulation',
			'regulation-rer',
			'admin-line',
			'line-operator-supervisor',
			'line-operator',
			'train-agent',
			'train-driver',
			'admin-rer',
		];
		let value = '';

		if (allowedRoles.includes(role)) {
			value = typeof main === 'string' ? main : associated.join(', ');
		} else {
			value = associated.join(', ');
		}

		return sortLines(value);
	}

	const excelUserList = userList.map(
		({ firstname, lastname, id, role, associated_line, main_line, active }) => ({
			Prenom: firstname,
			Nom: lastname,
			'Compte Matriculaire': id,
			'Profil (rôle)': t(`user:admin-user-list.list.user-info.role.${role}`),
			'Lignes autorisées (périmètre)': foundLineByRole(role, associated_line, main_line),
			Status: active ? 'Activé' : 'Désactivé',
		})
	);

	const generateExcel = async (data) => {
		try {
			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet('Users');

			worksheet.columns = [
				{ header: 'Compte matriculaire', key: 'Compte Matriculaire', width: 15 },
				{ header: 'Nom', key: 'Nom', width: 15 },
				{ header: 'Prénom', key: 'Prenom', width: 15 },
				{ header: 'Profil (rôle)', key: 'Profil (rôle)', width: 20 },
				{
					header: 'Lignes autorisées (périmètre)',
					key: 'Lignes autorisées (périmètre)',
					width: 30,
				},
				{ header: 'Status', key: 'Status', width: 10 },
			];

			if (data && data.length > 0) {
				worksheet.addRows(data);
			}

			worksheet.columns.forEach((column) => {
				let maxLength = 0;
				column.eachCell({ includeEmpty: true }, (cell) => {
					const columnLength = cell.value ? cell.value.toString().length : 10;
					if (columnLength > maxLength) {
						maxLength = columnLength;
					}
				});
				column.width = maxLength < 10 ? 10 : maxLength + 2;
			});

			const buffer = await workbook.xlsx.writeBuffer();
			const blob = new Blob([buffer], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});

			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = 'users.xlsx';
			link.click();
		} catch (error) {
			console.error('Erreur lors de la génération du fichier Excel:', error);
		}
	};

	const handleUserListGenerationClick = async () => {
		setLoading(true);
		try {
			await generateExcel(excelUserList);
		} finally {
			setLoading(false);
		}
	};

	const { filter, updateFilter, resetFilter } = useStoredFilter(
		filterConfig.storageName,
		filterConfig.filter,
		filterConfig.sort
	);

	const retrievedSearchedUser = useDebouncedAction(() => {
		const { search: q, role, associatedLine } = filter;
		searchUser({
			q,
			role,
			associatedLine,
		}).then((response) => setUserList(response?.data || []));
	}, 500);

	const subHeader = (
		<UserListSubHeader
			filter={filter}
			updateFilter={updateFilter}
			resetFilter={resetFilter}
			retrievedSearchedUser={retrievedSearchedUser}
			users={userList}
			handleUserListGenerationClick={handleUserListGenerationClick}
			loading={loading}
		/>
	);

	const renderUserRow = (user) => (
		<UserListRow
			key={user?.tech_id}
			className="user-list__item"
			displayedUser={user}
			updateList={retrievedSearchedUser}
		/>
	);

	useEffect(() => {
		retrievedSearchedUser(filter?.search);
		// ignore retrievedSearchedUser dependency to avoid infinite render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	return (
		<CdrPage className="admin-page" subheader={subHeader}>
			<ul className="admin-page__user-list">
				<li className="user-list__header">
					<span>{t('user:admin-user-list.list.header-label.number')}</span>
					<span>{t('user:admin-user-list.list.header-label.name')}</span>
					<span>{t('user:admin-user-list.list.header-label.firstname')}</span>
					<span>{t('user:admin-user-list.list.header-label.role')}</span>
					<span>{t('user:admin-user-list.list.header-label.perimeter')}</span>
					<span>{t('user:admin-user-list.list.header-label.status')}</span>
				</li>

				{userList.map(renderUserRow)}
			</ul>
		</CdrPage>
	);
};

export default AdminUserList;
