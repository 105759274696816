import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from '../../../../../../../theme';

const BlockedCarStepType2 = ({ formData, handleChange, handleValidate }) => {
	const { t } = useTranslation();

	return (
		<div className="content__steps">
			<div className="content__schema__step__2">
				<h3 className="content__subtitle">
					{t('result-sheet:popup.result-form.blocked-car-title2')}
				</h3>
				<div className="schema__base-schema-steps-type2">
					<div className="schema__checkbox-container-steps-type2">
						{formData.unlockedBogies.map((checked, index) => (
							<div key={index} className={`railcar-container-steps-type2 `}>
								<label className="checkbox-steps-type2">
									<input
										type="checkbox"
										name="unlockedBogies"
										checked={checked}
										onChange={(event) => handleChange(event, index)}
									/>
									<span className="checkbox-custom-steps-type2"></span>
								</label>
							</div>
						))}
						<div className="head-train"></div>
						<h3 className="title_header_train">
							{t('result-sheet:popup.result-form.title-head-train')}
						</h3>
					</div>
				</div>
			</div>
			<div className="content__actions">
				<Button
					variant="primary"
					onClick={handleValidate}
					label={t('result-sheet:popup.result-form.step-air-supply-validate')}
					style={{ height: '6vh', fontSize: '21px' }}
				/>
			</div>
		</div>
	);
};

export default BlockedCarStepType2;

BlockedCarStepType2.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
};
