import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './card-block-template.scss';

import { useAppSetupContext } from '../../../shared/context/app-setup-context/app-setup-context';

/**
 * Render the CardBlockTemplate
 * @param {object}props
 * @param {string} props.mainText
 * @param {string} props.classname
 * @param {string} props.linkTo
 * @param {string} props.title
 * @param {string} props.subtitle
 * @param {string} props.color
 * @param {boolean} props.isBinderGroup
 * @return {JSX.Element}
 * @constructor
 */
const CardBlockTemplate = (props) => {
	const {
		classname,
		mainText,
		linkTo,
		title,
		isBinderGroup,
		subTitle,
		color,
		onClick = () => {},
	} = props;
	const { user = {} } = useAppSetupContext();

	const { role } = user;

	const trainDriverStyle = {
		backgroundColor: 'white',
		border: '3px rgb(25, 15, 139) solid	',
		boxShadow: '0px 4px 0px rgba(0, 0, 0, 0.1)',
		marginBottom: '20px',
		marginTop: '20px',
		borderRadius: '10px',
		height: '7rem',
		width: '50rem',
		display: 'flex',
		justifyContent: 'center',
	};

	const trainDriverStyleFont = {
		fontWeight: 'bold',
		fontSize: '2rem',
		color: 'black',
		marginTop: '0.2rem',
		marginBottom: '0.2rem',
	};

	return (
		<div className={classNames('card', classname)}>
			<Link
				className={classNames('card__link', { 'card__link--group': isBinderGroup })}
				to={linkTo}
				onClick={onClick}
			>
				<div
					className="card__shortname"
					style={role !== 'train-driver' ? { background: color } : trainDriverStyle}
				>
					<div
						className="card__shortname__label"
						style={role === 'train-driver' ? trainDriverStyleFont : null}
					>
						{mainText}
					</div>
				</div>
				<span className="card__folder-ui" />
				<span className="card__folder-ui" />
				{!isBinderGroup && role !== 'train-driver' && (
					<>
						<div className="card__title">{title}</div>
						<div className="card__subtitle">{subTitle}</div>
					</>
				)}
			</Link>
		</div>
	);
};

export default CardBlockTemplate;

CardBlockTemplate.propTypes = {
	onClick: PropTypes.func,
	classname: PropTypes.string,
	mainText: PropTypes.string,
	linkTo: PropTypes.string,
	title: PropTypes.string,
	isBinderGroup: PropTypes.bool,
	subTitle: PropTypes.string,
	color: PropTypes.string,
};
