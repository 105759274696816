import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './text-field.scss';

import IconWrapper from '../../icon-wrapper/icon-wrapper';

/**
 * TextField component
 * @param {object} props
 * @param {string} props.value
 * @param {boolean} props.readOnly
 * @param {function} props.handleFocus
 * @param {function} props.handleBlur
 * @param {string} props.inputClassName
 * @param {string} props.label
 * @param {object} props.icon
 * @param {function} props.onChange
 * @param {string} props.name
 * @param {string} props.placeholder
 * @param {string} props.className
 * @param {boolean} props.invalid
 * @param {boolean} props.disabled
 * @param {string} props.helperText
 * @returns {JSX.Element}
 */
const TextField = ({
	value = '',
	readOnly,
	handleFocus,
	handleBlur,
	inputClassName,
	label,
	icon,
	onChange,
	name,
	placeholder,
	className,
	invalid,
	disabled,
	helperText,
	children,
	type = 'text',
}) => {
	const inputRef = useRef();

	const handleLabelFocus = () => {
		inputRef.current.focus();
	};

	const containerClassName = useMemo(
		() =>
			classNames(className, 'text-field', {
				'text-field--invalid': invalid,
				'text-field--disabled': disabled,
			}),
		[className, invalid, disabled]
	);

	return (
		<div className={containerClassName}>
			<input
				ref={inputRef}
				className={classNames('text-field__input', inputClassName, {
					'text-field__input--valid': value,
				})}
				type={type}
				name={name}
				onFocus={handleFocus}
				onBlur={handleBlur}
				value={value}
				readOnly={readOnly}
				onChange={onChange}
				placeholder={placeholder}
				disabled={disabled}
			/>
			<label onClick={handleLabelFocus} className="text-field__label">
				{label}
			</label>
			{icon && <IconWrapper className="text-field__icon" Component={icon} />}
			{helperText && <span className="text-field__helper-text">{helperText}</span>}
			{children}
		</div>
	);
};

export default TextField;

TextField.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	readOnly: PropTypes.bool,
	handleFocus: PropTypes.func,
	handleBlur: PropTypes.func,
	inputClassName: PropTypes.string,
	label: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType, PropTypes.object]),
	onChange: PropTypes.func,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	disabled: PropTypes.bool,
	helperText: PropTypes.string,
	children: PropTypes.node,
	type: PropTypes.oneOf(['text', 'number']),
};
