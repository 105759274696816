import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';
const ResumeCheckboxes = ({ updatedResultCheck }) => {
	const { t } = useTranslation();

	return (
		<div className="content-resume-checkbox">
			<h3>{t('result-sheet:popup.result-form.final-step.resume-checkbox-type1')}</h3>
			<div className="resume-checkboxes">
				{updatedResultCheck.map((item, index) => (
					<div className="resume-checkboxe" key={index}>
						<input
							type="checkbox"
							checked={item.checked}
							style={{ accentColor: '#0022c0', width: '20px', height: '20px', marginTop: '40px' }}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default ResumeCheckboxes;

ResumeCheckboxes.propTypes = {
	updatedResultCheck: PropTypes.array.isRequired,
};
