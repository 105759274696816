import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import { Button } from '../../../../../../../theme';
import RailcarType4 from '../../components/railcar-type4/railcar-type4';
import InfoPression from '../info-pression/infoPression';

const PressionType4 = ({ handleValidate, formData, handleChange, formatType }) => {
	const { t } = useTranslation();

	const notCePressions = formData.cePressions.every((item) => item === null);

	return (
		<div className="content__step">
			<div className="content__schema_railcartype4_pression">
				<RailcarType4 formatType={formatType} />
			</div>
			<div className="content__title__step1__type4">
				<h3 className="content__subtitle">
					{t('result-sheet:popup.result-form.step-pression-title1')}
				</h3>
			</div>
			<div className="pressure-container">
				{formData.cePressions.map((value, index) => (
					<InfoPression
						key={`pression-value-${index}`}
						handleChange={handleChange}
						index={index}
						value={value}
					/>
				))}
			</div>
			<div className="content__actions">
				<Button
					variant="primary"
					onClick={handleValidate}
					label={t('result-sheet:popup.result-form.step-pressure-validate')}
					disabled={notCePressions}
					style={{ height: '5vh', fontSize: '20px' }}
				/>
			</div>
		</div>
	);
};

export default PressionType4;

PressionType4.propTypes = {
	handleValidate: PropTypes.func.isRequired,
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	formatType: PropTypes.array.isRequired,
};
