import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import OrangeBogieCheckbox from '../../../components/orange-bogie-checkbox/orange-bogie-checkbox';
import RedRailCarFinalStep from '../../../components/railcar-checkbox/final-step/final-step';
import RailcarType1 from '../../../components/railcar-type1/railcar-type1';
import PreviousResultHeader from '../../components/previous-result-header/header/header';
import PreviousResultPression from '../../components/previous-result-header/pression/pression';
const PreviousResultType1 = ({ formData, returnFirstStep }) => {
	const { t } = useTranslation();

	return (
		<div className="content__schema__final">
			<PreviousResultHeader returnFirstStep={returnFirstStep} />
			<div className="schema__base-schema__final_pression pression-type1">
				<PreviousResultPression pression={formData.cePressions[0]} />
			</div>
			<div className="previous-result-railcar">
				<RailcarType1 isPreviousResult />
			</div>
			<div className="previous-result-non-orange-bogie">
				<h3 className="previous-result-subtitle">
					{t('result-sheet:popup.result-form.final-step.non-orange-bogies')}
				</h3>
				<div className="previous-result-orange-bogie">
					{formData.nonOrangeBogies.map((checked, index) => {
						const isLast = index === formData.nonOrangeBogies.length - 1;
						return (
							<OrangeBogieCheckbox
								key={index}
								checked={checked}
								disabled
								index={index}
								isLast={isLast}
							/>
						);
					})}
				</div>
			</div>
			<div className="previous-result-red-info">
				<div className="subtitles">
					<h3 className="previous-result-subtitle">
						{t('result-sheet:popup.result-form.final-step.red-railcar')}
					</h3>
					<h3 className="previous-result-subtitle">
						{t('result-sheet:popup.result-form.final-step.red-xr-fr')}
					</h3>
				</div>
				<div className="previous-result-red-railcars">
					{formData.redRailcars.map((checked, index) => {
						return (
							<RedRailCarFinalStep
								key={index}
								redRailcarChecked={checked}
								xrFrChecked={formData.redXrFr[index]}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default PreviousResultType1;

PreviousResultType1.propTypes = {
	formData: PropTypes.object.isRequired,
	returnFirstStep: PropTypes.func.isRequired,
};
