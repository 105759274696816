import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const OrangeBogieCheckbox = ({
	checked,
	handleChange,
	index,
	type,
	nonOrangeBogies,
	isLast,
	isEndState,
}) => {
	return (
		<div
			data-testid="orange-bogie-chekbox-container"
			className={`${type ?? `orange-bogie-chekbox-container${type}`}${isLast ? '-last-checkbox' : ''}`}
		>
			<div className={`circle ${checked ? 'border-primary' : 'border-orange'}`}>
				<input
					checked={checked}
					name={
						type === 'type4' && nonOrangeBogies !== 'nonOrangeBogies'
							? 'redXrFr'
							: 'nonOrangeBogies'
					}
					onChange={(event) => !isEndState && handleChange(event, index)}
					type="checkbox"
					style={{ accentColor: '#0022c0', width: '20px', height: '20px' }}
					disabled={isEndState}
				/>
			</div>
		</div>
	);
};

export default OrangeBogieCheckbox;

OrangeBogieCheckbox.propTypes = {
	isEndState: PropTypes.bool,
	checked: PropTypes.bool.isRequired,
	handleChange: PropTypes.func,
	index: PropTypes.number.isRequired,
	isLast: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	nonOrangeBogies: PropTypes.string.isRequired,
};
