import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './sheet-type.scss';

import { unpadStartNumberInside } from '../../../../shared';
import { isTrainBinderType } from '../../../binder/utils/binder-type';
import { formatSheetNumberDiamond, sheetTypeMap } from '../../index';

// max sheetNumber length is 5 for a diamond shape
const SHEETNUMBER_MAX_LENGTH = 5;

/**
 * Render the SheetType component
 * @param {object} props
 * @param {string} props.className
 * @param {"man"|"child"|"parent"|"determination"|"m"|"resume"|"appendix"} props.type
 * @param {string} props.sheetNumber
 * @param {string} props.color
 * @param {string} props.signalInfo
 * @param {string} [props.binderType = ""]
 * @param {boolean} [props.isBinderTrain = false]
 * @returns {JSX.Element}
 * @constructor
 */
const SheetType = ({
	className,
	type = 'parent',
	sheetNumber,
	color,
	binderType = '',
	isBinderTrain = false,
	signalInfo = {},
	isMaterial = false,
}) => {
	const container = useRef('');
	const isTrainBinder = isBinderTrain || isTrainBinderType(binderType);

	useEffect(() => {
		if (container?.current?.style) {
			container.current.style.setProperty('--binder-color', color || null);
		}
	}, [color]);

	const initClassName = () => {
		const modifier = {
			'sheet-type--man': binderType === 'man',
			'sheet-type--train': isTrainBinder,
			'sheet-type--child': type === sheetTypeMap.child,
			'sheet-type--parent': type === sheetTypeMap.parent,
			'sheet-type--determination': type === sheetTypeMap.determination,
			'sheet-type--initial': type === sheetTypeMap.initial,
			'sheet-type--m': type === sheetTypeMap.m,
			'sheet-type--mdetermination': type === sheetTypeMap.mdetermination,
			'sheet-type--resume': type === sheetTypeMap.resume,
			'sheet-type--appendix': type === sheetTypeMap.appendix,
		};
		return classnames('sheet-type', modifier, className);
	};

	const createSheetNumber = () => {
		let text = sheetNumber;

		if (!isMaterial) {
			text = unpadStartNumberInside(sheetNumber || '') || sheetNumber;
		}

		if (text?.length > SHEETNUMBER_MAX_LENGTH) {
			const isDiamondShape =
				isTrainBinder && (type === sheetTypeMap.resume || type === sheetTypeMap.determination);
			if (isDiamondShape) {
				return formatSheetNumberDiamond(text);
			}
		}
		return text;
	};

	return (
		<span ref={container} className={initClassName()}>
			<div>
				{signalInfo?.title && <span>{`${signalInfo.title} `}</span>}
				{signalInfo?.destination && <span>{`/ ${signalInfo.destination} `}</span>}
			</div>
			<span className="sheet-type__sheetnumber">{createSheetNumber()}</span>
		</span>
	);
};
export default SheetType;

SheetType.propTypes = {
	className: PropTypes.string,
	isMaterial: PropTypes.bool,
	type: PropTypes.oneOf([
		'man',
		'child',
		'parent',
		'determination',
		'm',
		'resume',
		'appendix',
		'',
		'initial',
	]),
	sheetNumber: PropTypes.string,
	color: PropTypes.string,
	binderType: PropTypes.string,
	isBinderTrain: PropTypes.bool,
	signalInfo: PropTypes.shape({
		title: PropTypes.string,
		destination: PropTypes.string,
	}),
};
