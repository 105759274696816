import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';
const ResumeCheckBoxes = ({ updatedResultCheck }) => {
	const { t } = useTranslation();

	return (
		<div className="content__step5">
			<h3 className="content__subtitle">
				{t('result-sheet:popup.result-form.title_step-4-type3')}
			</h3>
			<div className="schema__checkbox-container-final-step4-type3">
				{updatedResultCheck.map((checked, index) => (
					<div key={index} className={`railcar-container-steps-type3 `}>
						<label className="checkbox-steps-type2">
							<input type="checkbox" name="unlockedBogies" checked={checked} />
							<span className="checkbox-custom-steps-type2"></span>
						</label>
					</div>
				))}
				<div className="head-train-type3"></div>
			</div>
		</div>
	);
};

export default ResumeCheckBoxes;

ResumeCheckBoxes.propTypes = {
	updatedResultCheck: PropTypes.array.isRequired,
};
