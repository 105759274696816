import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
const RailcarType1 = ({ isNonOrangeBogie, isPreviousResult }) => {
	const backgroundColor = isNonOrangeBogie ? '' : 'background-grey';
	const getMargedBottom = () => {
		let margedBottom = 'marged-bottom';
		if (isNonOrangeBogie || isPreviousResult) margedBottom = '';
		return margedBottom;
	};
	return (
		<div className={`car-content ${getMargedBottom()}`}>
			<div className="triangle" />
			<div className="little-dark-rectangle" />
			<div className="car-container">
				<div className={`car ${backgroundColor}`}>S</div>
				<div className="car-join" />
				<div className={`car ${backgroundColor}`}>N</div>
				<div className="car-join" />
				<div className={`car ${backgroundColor}`}>N</div>
				<div className="car-join" />
				<div className={`car ${backgroundColor}`}>N3</div>
				<div className="car-join" />
				<div className={`car ${backgroundColor}`}>S</div>
			</div>
		</div>
	);
};

export default RailcarType1;

RailcarType1.propTypes = {
	isNonOrangeBogie: PropTypes.bool,
	isPreviousResult: PropTypes.bool,
};
