import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import RailcarType5 from '../../../components/railcar-type5/railcar-type5';
import PreviousResultHeader from '../../components/previous-result-header/header/header';

import PreviousResultCheckboxes from './components/checkboxes/checkboxes';

const PreviousResultType5 = ({ formData, returnFirstStep }) => {
	const { t } = useTranslation();
	const railcarTitles = Array(6).fill('V');
	return (
		<div className="content__schema__final">
			<PreviousResultHeader returnFirstStep={returnFirstStep} />
			<div className="railcar-title-previous-result-container">
				<div className="railcar-title-previous-result-before">
					<h3>{t('result-sheet:popup.result-form.before')}</h3>
				</div>
				<div className="railcar-title-previous-result">
					{railcarTitles.map((title, index) => (
						<Fragment key={index}>
							<h3 className="V">{`${title}${index + 1}`}</h3>
							{index !== railcarTitles.length - 1 && (
								<h3 className="XCE">{t('result-sheet:popup.result-form.xce')}</h3>
							)}
						</Fragment>
					))}
				</div>
			</div>
			<div className="previous-result-railcar-type5">
				<RailcarType5
					cePressions={formData.cePressions}
					closedXce={formData.closedXce}
					isFinalStep={true}
				/>
			</div>
			<PreviousResultCheckboxes
				checkedArray={formData.poweredBogies}
				subtitle={t('result-sheet:popup.result-form.final-step.powered-bogies')}
			/>
			<PreviousResultCheckboxes
				checkedArray={formData.unlockedBogies}
				subtitle={t('result-sheet:popup.result-form.final-step.unlocked-bogies')}
			/>
			<PreviousResultCheckboxes
				checkedArray={formData.lockedBogies}
				isLast={true}
				subtitle={t('result-sheet:popup.result-form.final-step.locked-bogies')}
			/>
		</div>
	);
};

export default PreviousResultType5;

PreviousResultType5.propTypes = {
	formData: PropTypes.object.isRequired,
	returnFirstStep: PropTypes.func.isRequired,
};
