import PropTypes from 'prop-types';

import './styles.scss';

const Stepper = ({ stepsList, setActiveStep, activeStep }) => {
	return (
		<div className="result-form__stepper">
			{stepsList.map((label, index) => (
				<div
					key={label}
					className={`stepper__step ${activeStep >= index ? 'active' : ''}`}
					onClick={() => activeStep >= index && setActiveStep(index)}
				>
					<div className="step__indicator">{index + 1}</div>
					<div className="step__label">{label}</div>
				</div>
			))}
		</div>
	);
};

export default Stepper;

Stepper.propTypes = {
	activeStep: PropTypes.number.isRequired,
	stepsList: PropTypes.array.isRequired,
	setActiveStep: PropTypes.func.isRequired,
};
