import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import '../styles.scss';

import PreviousResultHeader from '../../components/previous-result-header/header/header';

const PreviousResult = ({ resultCheck, returnFirstStep, pressionValues, formData }) => {
	const { t } = useTranslation();

	return (
		<div className="content__schema__final content-type2">
			<PreviousResultHeader returnFirstStep={returnFirstStep} />
			<div className="schema__base-schema__final_pression">
				{pressionValues.map((pression, index) => (
					<div key={index} className="content__result_pression">
						{t('result-sheet:popup.result-form.title-step-pression')}
						<div className="result_pression">{`${pression} Kpa`}</div>
					</div>
				))}
			</div>
			<div className="schema__base-schema__final_check">
				<div className="schema__container_final_check">
					<h3 className="content__subtitle_final_step_type2">
						{t('result-sheet:popup.result-form.final-step.close-xce')}
					</h3>
					<h3 className="content__subtitle_final_step_type2">
						{t('result-sheet:popup.result-form.final-step.no-alimentation')}
					</h3>
					<h3 className="content__subtitle_final_step_type2">
						{t('result-sheet:popup.result-form.final-step.no-blocked')}
					</h3>
					<h3 className="content__subtitle_final_step_type2">
						{t('result-sheet:popup.result-form.final-step.close-xf')}
					</h3>
				</div>
				<div className="schema__checkbox-container_type2">
					<div className="schema__checkbox-container-train-type2">
						<div className="schema__checkbox-container-step-previous-type2 ">
							{formData.closedXce.map((checked, index) => (
								<div key={index} className="railcar-container-final-step">
									<label key={index} className="checkbox-pression">
										<input type="checkbox" name="closedXce" checked={checked} />
										<span className="checkbox-custom"></span>
									</label>
								</div>
							))}
						</div>
						<div className="head__train__previous__final"></div>
					</div>
					<div className="content__result_check">
						{resultCheck.map((item, index) => (
							<div key={index} className={`railcar-container-steps-final`}>
								<div className="content__checkbox-final">
									<div className="checkbox-steps-final">
										<input type="checkbox" checked={item.airSupplyWagon === false} readOnly />
										<span
											className="checkbox-custom-steps-final"
											style={item.airSupplyWagon ? { opacity: 0.33 } : { opacity: 0.77 }}
										></span>
									</div>
									<div className="checkbox-steps-final">
										<input type="checkbox" checked={item.blockedCarWagon} readOnly />
										<span
											className="checkbox-custom-steps-final"
											style={item.blockedCarWagon ? { opacity: 0.7 } : { opacity: 0.33 }}
										></span>
									</div>
									<div className="checkbox-steps-final">
										<input type="checkbox" checked={item.closeXfWagon} readOnly />
										<span
											className="checkbox-custom-steps-final"
											style={item.closeXfWagon ? { opacity: 0.7 } : { opacity: 0.33 }}
										></span>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreviousResult;

PreviousResult.propTypes = {
	pressionValues: PropTypes.array.isRequired,
	returnFirstStep: PropTypes.func.isRequired,
	resultCheck: PropTypes.array.isRequired,
};
