import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import { Button } from '../../../../../../../theme';
import RailcarType5 from '../../components/railcar-type5/railcar-type5';
const PressionType5 = ({ formData, handleChange, handleValidate }) => {
	const { t } = useTranslation();
	const notCePressions = formData.cePressions.every((item) => item === null);
	const railcarTitles = Array(6).fill('V');
	return (
		<>
			<div className="subtitle-container">
				<h3 className="content__subtitle">
					{t('result-sheet:popup.result-form.step-pression-title2')}
				</h3>
			</div>
			<div className="railcar-title">
				{railcarTitles.map((title, index) => (
					<Fragment key={index}>
						<h3 className="V">{`${title}${index + 1}`}</h3>
						{index !== railcarTitles.length - 1 && (
							<h3 className="XCE">{t('result-sheet:popup.result-form.xce')}</h3>
						)}
					</Fragment>
				))}
			</div>
			<RailcarType5
				cePressions={formData.cePressions}
				closedXce={formData.closedXce}
				handleChange={handleChange}
			/>
			<div className="content__actions">
				<Button
					variant="primary"
					onClick={handleValidate}
					label={t('result-sheet:popup.result-form.step-pressure-validate')}
					disabled={notCePressions}
					style={{ height: '6vh', fontSize: '21px' }}
					data-testid="validate-button"
				/>
			</div>
		</>
	);
};

export default PressionType5;

PressionType5.propTypes = {
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
	formData: PropTypes.object.isRequired,
};
