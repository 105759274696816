import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import { Button } from '../../../../../../../theme';
import RailcarType4 from '../../components/railcar-type4/railcar-type4';

const RedRailcarType4 = ({ formData, handleChange, handleValidate, formatType }) => {
	const { t } = useTranslation();

	return (
		<div className="content-container">
			<RailcarType4 formatType={formatType} />
			<h3 className="content__subtitle">
				{t('result-sheet:popup.result-form.step-red-railcar-type4')}
			</h3>

			<div className="schema__checkbox-container-steps-type4">
				{formData.redRailcars.map((checked, index) => (
					<div key={index} className={`railcar-container-steps-type4 `}>
						<label className="checkbox-steps-type2">
							<input
								type="checkbox"
								name="redRailcars"
								checked={checked}
								onChange={(event) => handleChange(event, index)}
								style={{ accentColor: '#0022c0' }}
							/>
							<span className="checkbox-custom-steps-type2"></span>
						</label>
					</div>
				))}
			</div>

			<div className="content__actions">
				<Button
					variant="primary"
					onClick={handleValidate}
					label={t('result-sheet:popup.result-form.validate')}
					style={{ height: '5vh', fontSize: '20px' }}
				/>
			</div>
		</div>
	);
};

export default RedRailcarType4;

RedRailcarType4.propTypes = {
	formData: PropTypes.object.isRequired,
	formatType: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
};
