import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import TotalCount from '../components/total-count/total-count';

import CallPCC from './call-pcc/call-pcc';
import PreviousResult from './previous-result/previous-result';
import Step5Type2 from './resume-checkboxes/resume-checkboxes';

const FinalStepType2 = ({ formData, handleChange, returnFirstStep, setValidCheckbox }) => {
	const { t } = useTranslation();
	const newClosedXce = formData.closedXce.slice(1, -1);

	const firstCloseXce = formData.closedXce[0];
	const lastCloseXce = formData.closedXce[formData.closedXce.length - 1];

	const updatenewTab = newClosedXce.reduce((acc, curr, index) => {
		if (index % 2 === 0) {
			acc.push(curr);
		} else {
			acc[acc.length - 1] = acc[acc.length - 1] || curr;
		}
		return acc;
	}, []);

	const finalCloseXce = [firstCloseXce, ...updatenewTab, lastCloseXce];

	const resultCheck = formData.poweredBogies.map((_value, index) => ({
		airSupplyWagon: formData.poweredBogies[index],
		blockedCarWagon: formData.unlockedBogies[index],
		closeXfWagon: formData.closedXf[index],
		closedXceWagon: finalCloseXce[index],
	}));

	return (
		<div className="content__final__step">
			<PreviousResult
				pressionValues={formData.cePressions}
				returnFirstStep={returnFirstStep}
				resultCheck={resultCheck}
				formData={formData}
			/>
			<div className="content__steps">
				<Step5Type2
					formData={formData}
					resultCheck={resultCheck}
					setValidCheckbox={setValidCheckbox}
				/>
				<div className="divider" />
				<TotalCount
					checkCounter={formData.checkCounter}
					handleChange={handleChange}
					isLarge
					subtitle={t('result-sheet:popup.result-form.final-step.step6-title')}
					TotalCountText={t('result-sheet:popup.result-form.final-step.total-count-railcar')}
				/>
				<CallPCC />
			</div>
		</div>
	);
};

export default FinalStepType2;

FinalStepType2.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	returnFirstStep: PropTypes.func.isRequired,
	setValidCheckbox: PropTypes.func.isRequired,
};
