import React from 'react';
import PropTypes from 'prop-types';

import '../styles.scss';

const RedRailCarFinalStep = ({ redRailcarChecked, xrFrChecked }) => {
	return (
		<div className="container">
			<div className={`top-bar ${redRailcarChecked ? 'border-secondary' : 'border-grey'}`}>
				<input
					checked={redRailcarChecked}
					name="redRailcars"
					type="checkbox"
					disabled
					style={{ width: '25px', height: '25px', marginTop: '10px' }}
				/>
			</div>
			<div
				className={`middle-bar border-top-grey ${redRailcarChecked ? 'border-side-secondary' : 'border-side-grey'}`}
			/>
			<div className="middle-bar" />
			<div className={`circle ${xrFrChecked ? 'border-secondary' : 'border-grey'}`}>
				<input
					checked={xrFrChecked}
					name="redXrFr"
					type="checkbox"
					disabled
					style={{ width: '25px', height: '25px' }}
				/>
			</div>
		</div>
	);
};

export default RedRailCarFinalStep;

RedRailCarFinalStep.propTypes = {
	redRailcarChecked: PropTypes.bool.isRequired,
	xrFrChecked: PropTypes.bool.isRequired,
};
