import { http } from '../../config';
import { getNetworkState } from '../../config/offline';
import { getEntity, setEntity } from '../../shared/cache-access/entity/entity-utils';

const resultSheetRootUrl = '/result-sheet';

/**
 * @typedef {Object} ResultSheet
 * @property {string} tech_id - Technical identifier of the result sheet
 * @property {string} processTechId - Technical identifier of the associated procedure
 * @property {Object} content - Content of the result sheet
 * @property {Date} created_at - Creation date
 * @property {string} line - Associated line
 * @property {string} materielTechId - Technical identifier of the material
 * @property {Array} cePressions - CE pressures
 * @property {Array} [closedXce] - Closed XCE
 * @property {Array} [poweredBogies] - Powered bogies
 * @property {Array} [unlockedBogies] - Unlocked bogies
 * @property {Array} [redRailcars] - Red railcars
 * @property {Array} [redXrFr] - Red XR FR
 * @property {Array} [nonOrangeBogies] - Non orange bogies
 * @property {Array} [lockedBogies] - Locked bogies
 * @property {boolean} [closedXf] - Closed XF
 * @property {boolean} [unlockedAxle] - Unlocked axle
 */

/**
 * Fetch the list of result sheets for a given process
 * @param {string} processTechId - Technical identifier of the process
 * @returns {Promise<import("axios").AxiosResponse<ResultSheet[]>>}
 */
const fetchResultSheetByProcessId = async (processTechId) => {
	return http.get(`${resultSheetRootUrl}/${processTechId}/last`, {
		handleOfflineResponse: async () => {
			const storedResultSheet = await getEntity('resultSheet', processTechId);
			return storedResultSheet?.data || [];
		},
	});
};

/**
 * Create a new result sheet
 * @param {ResultSheet} resultSheet - Data of the result sheet
 * @returns {Promise<import("axios").AxiosResponse<ResultSheet>>}
 */
const postResultSheet = async (resultSheet) => {
	return http.post(resultSheetRootUrl, resultSheet, {
		handleCacheSync: async (config, data, metadata) => {
			const { processTechId } = resultSheet;
			let storedResultSheets;
			try {
				storedResultSheets = await getEntity('resultSheet', processTechId);
			} catch {
				storedResultSheets = { data: [] };
			}

			const newData = Array.isArray(storedResultSheets.data)
				? [...storedResultSheets.data, data]
				: [data];

			await setEntity('resultSheet', processTechId, data.tech_id, newData, {
				...metadata,
				isPending: getNetworkState() === 'offline',
			});
		},
		handleOfflineResponse: async () => {
			const storedResultSheet = await getEntity('resultSheet', resultSheet.processTechId);
			return storedResultSheet?.data?.[storedResultSheet.data.length - 1];
		},
		entity: 'resultSheet',
		ref: resultSheet.processTechId,
	});
};

export { fetchResultSheetByProcessId, postResultSheet };
