import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const FormSteps = ({
	formatType,
	formData,
	activeStep,
	handleChange,
	handleValidate,
	returnFirstStep,
	setValidCheckbox,
}) => {
	const StepComponent = formatType.steps[activeStep];

	if (!StepComponent) {
		console.warn(`No component found for type ${formatType} and step ${activeStep}`);
		return null;
	}

	const isEndState = activeStep === formatType.stepsList.length;

	return (
		<div className="step-container">
			<div className="result-form__content">
				<StepComponent
					formData={formData}
					handleChange={handleChange}
					formatType={formatType}
					handleValidate={handleValidate}
					returnFirstStep={returnFirstStep}
					activeStep={activeStep}
					isEndState={isEndState}
					setValidCheckbox={setValidCheckbox}
				/>
			</div>
		</div>
	);
};

FormSteps.propTypes = {
	activeStep: PropTypes.number.isRequired,
	formatType: PropTypes.object,
	formData: PropTypes.object,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
	setValidCheckbox: PropTypes.func.isRequired,
};

export default FormSteps;
