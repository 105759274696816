/**
 * get local storage contents
 * @param {string} storageName
 * @returns {object}
 */
const getLocalStorageContent = (storageName) => {
	if (storageName) {
		return JSON.parse(localStorage.getItem(storageName) || '{}');
	}
	return {};
};

/**
 * Set the local storage content
 * @param {string} storageName
 * @param {object} [content={}]
 */
const setLocaleStorageContent = (storageName, content = {}) =>
	localStorage.setItem(storageName, JSON.stringify(content));

/**
 * Update the content property in local storage
 * @param {"csfr-token"|"spr-context"|"sheet-read-link-context"} storageName
 * @param {"associatedLine"|"side"|"currentBinder"|"trainBinderCount","sheetLinks"} key
 * @param {any} value
 */
const setStoredLocalStorageContentProperty = (storageName, key, value) => {
	const content = getLocalStorageContent(storageName);
	setLocaleStorageContent(storageName, { ...content, [key]: value });
};

export { getLocalStorageContent, setLocaleStorageContent, setStoredLocalStorageContentProperty };
