import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TotalCount from '../components/total-count/total-count';

import PreviousResult from './previous-result/previous-result';
import Step5Type4 from './resume-checkboxes/step5';

const FinalStepType4 = ({
	formData,
	handleChange,
	formatType,
	isEndState,
	returnFirstStep,
	setValidCheckbox,
}) => {
	const { t } = useTranslation();
	const resultCheck = formData.redXrFr.map((_value, index) => ({
		redRailCarTrain: formData.redRailcars[index],
		redXrFrCarTrain: formData.redXrFr[index],
		nonOrangeBogiesCarTrain: formData.nonOrangeBogies[index],
	}));

	const updatedResultCheck = resultCheck.map((item) => ({
		...item,
		checked: item.redXrFrCarTrain && item.nonOrangeBogiesCarTrain && item.redRailCarTrain,
	}));

	const countChecked = updatedResultCheck.filter((item) => item.checked).length;

	useEffect(() => {
		setValidCheckbox(countChecked);
	}, [countChecked, setValidCheckbox]);

	return (
		<div className="content__final__step">
			<PreviousResult
				pressionValues={formData.cePressions}
				returnFirstStep={returnFirstStep}
				formData={formData}
				formatType={formatType}
			/>
			<Step5Type4
				updatedResultCheck={updatedResultCheck}
				formatType={formatType}
				isEndState={isEndState}
			/>
			<div className="divider" />
			<TotalCount
				checkCounter={formData.checkCounter}
				handleChange={handleChange}
				isLarge={true}
				subtitle={t('result-sheet:popup.result-form.final-step.step6-title')}
				TotalCountText={t('result-sheet:popup.result-form.final-step.finish-inactive-bogie')}
			/>
		</div>
	);
};

export default FinalStepType4;

FinalStepType4.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	returnFirstStep: PropTypes.number.isRequired,
	formatType: PropTypes.object.isRequired,
	isEndState: PropTypes.bool.isRequired,
	setValidCheckbox: PropTypes.func.isRequired,
};
