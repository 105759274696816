import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import '../styles.scss';

const Step5Type2 = ({ resultCheck, setValidCheckbox }) => {
	const { t } = useTranslation();

	const updatedResultCheck = resultCheck.map((item) => ({
		...item,
		checked:
			item.blockedCarWagon ||
			item.closeXfWagon ||
			item.airSupplyWagon === false ||
			item.closedXceWagon,
	}));

	const countChecked = updatedResultCheck.filter((item) => item.checked).length;

	useEffect(() => {
		setValidCheckbox(countChecked);
	}, [countChecked, setValidCheckbox]);

	const railCarLabels = ['5e voiture', '4e voiture', '3e voiture', '2e voiture', '1re voiture'];
	const style = ['railcar', 'railcar', 'railcar', 'railcar', 'headrailcar'];
	return (
		<div className="content__step5">
			<h3 className="content__subtitle">{t('result-sheet:popup.result-form.title_step-5')}</h3>
			<div className="schema__checkbox-container-step-final-type2">
				{updatedResultCheck.map((item, index) => (
					<div key={index} className={`railcar-containe-step-final ${style[index]}`}>
						<div className={`railcar-container-step-final-type2 `}>
							<label className="checkbox-steps-type2">
								<input type="checkbox" name="checkboxFinalSteps" checked={item.checked} />
								<span className="checkbox-custom-steps-type2"></span>
							</label>
						</div>
						<span className="railcar-label">{railCarLabels[index]}</span>
					</div>
				))}
				<div className="head__train__final"></div>
			</div>
		</div>
	);
};

export default Step5Type2;

Step5Type2.propTypes = {
	resultCheck: PropTypes.array.isRequired,
	setValidCheckbox: PropTypes.func.isRequired,
};
