import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import { Button } from '../../../../../../../theme';
import OrangeBogieCheckbox from '../../components/orange-bogie-checkbox/orange-bogie-checkbox';
import RailcarType4 from '../../components/railcar-type4/railcar-type4';
import RailcarXrType4 from '../../components/railcar-type4/railcar-xr-type4';

const RedXrFrType4 = ({ handleValidate, formData, handleChange, formatType }) => {
	const { t } = useTranslation();
	const type = 'type4';
	const steps = 'steps';

	return (
		<div className="content-container">
			<RailcarType4 formatType={formatType} />
			<h3 className="content__subtitle">
				{t('result-sheet:popup.result-form.step-red-xr-fr-type4')}
			</h3>

			<div className="checkbox-container-steps-type4 ">
				<RailcarXrType4 steps={steps} formatType={formatType} />
				<div className={`orange-bogie-checkbox-type4 ${formatType.type}`}>
					{formData.redXrFr.map((checked, index) => {
						return (
							<OrangeBogieCheckbox
								key={index}
								checked={checked}
								handleChange={handleChange}
								index={index}
								type={type}
							/>
						);
					})}
				</div>
			</div>

			<div className="content__actions">
				<Button
					variant="primary"
					onClick={handleValidate}
					label={t('result-sheet:popup.result-form.validate')}
					style={{ height: '5vh', fontSize: '20px' }}
				/>
			</div>
		</div>
	);
};

export default RedXrFrType4;

RedXrFrType4.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
	formatType: PropTypes.string.isRequired,
};
