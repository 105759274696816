import AirSupplyType2 from '../steps/air-supply/type2/type2';
import BlockedCarStepType2 from '../steps/blocked-car/type2/type2';
import BlockedCarStepType3 from '../steps/blocked-car/type3.js/type3';
import CloseXfStepType2 from '../steps/close-xf/type2/type2';
import CloseXfStepType3 from '../steps/close-xf/type3/type3';
import FinalStepType1 from '../steps/final-step/type1/type1';
import FinalStepType2 from '../steps/final-step/type2/type2';
import FinalStepType3 from '../steps/final-step/type3/type3';
import FinalStepType4 from '../steps/final-step/type4/type4';
import FinalStepType5 from '../steps/final-step/type5/type5';
import LockedBogiesType5 from '../steps/locked-bogies/type5/type5';
import NonOrangeBogiesType1 from '../steps/non-orange-bogies/type1/type1';
import NonOrangeBogiesType4 from '../steps/non-orange-bogies/type4/type4';
import PoweredBogiesType5 from '../steps/powered-bogies/type5/type5';
import PressionType1 from '../steps/pression/type1/type1';
import PressionType2 from '../steps/pression/type2/type2';
import PressionType3 from '../steps/pression/type3/type3';
import PressionType4 from '../steps/pression/type4/type4';
import PressionType5 from '../steps/pression/type5/type5';
import RedRailcarType1 from '../steps/red-railcar/type1/type1';
import RedRailcarType4 from '../steps/red-railcar/type4/type4';
import RedXrFrType1 from '../steps/red-xr-fr/type1/type1';
import RedXrFrType4 from '../steps/red-xr-fr/type4/type4';
import UnlockedBogiesType5 from '../steps/unlocked-bogies/type5';

export const formatTypes = {
	first: {
		stepsList: ['PRESSION CE', 'Voitures en ROUGE', 'XR, FR en ROUGE', 'Bogies NON ORANGE'],
		lines: ['2', '5', '9'],
		steps: {
			0: PressionType1,
			1: RedRailcarType1,
			2: RedXrFrType1,
			3: NonOrangeBogiesType1,
			4: FinalStepType1,
		},
	},
	second: {
		stepsList: [
			'PRESSION CE et XCE',
			'Alimentation en air comprimée',
			'Voitures non bloquées',
			'XF fermé(s)',
		],
		lines: ['3', '6', '8', '10', '12', '13'],
		steps: {
			0: PressionType2,
			1: AirSupplyType2,
			2: BlockedCarStepType2,
			3: CloseXfStepType2,
			4: FinalStepType2,
		},
		sheet: 'type-2',
	},

	third: {
		stepsList: ['PRESSION CE et XCE', 'Etat XF', 'Etat essieux'],
		lines: ['7'],
		steps: {
			0: PressionType3,
			1: CloseXfStepType3,
			2: BlockedCarStepType3,
			3: FinalStepType3,
		},
	},
	fourth: {
		stepsList: ['PRESSION CE', 'Voitures en ROUGE', 'XR, FR en ROUGE', 'Bogies NON ORANGE'],
		lines: ['4', '11'],
		steps: {
			0: PressionType4,
			1: RedRailcarType4,
			2: RedXrFrType4,
			3: NonOrangeBogiesType4,
			4: FinalStepType4,
		},
	},
	fourthLong: {
		stepsList: ['PRESSION CE', 'Voitures en ROUGE', 'XR, FR en ROUGE', 'Bogies NON ORANGE'],
		lines: ['14'],
		steps: {
			0: PressionType4,
			1: RedRailcarType4,
			2: RedXrFrType4,
			3: NonOrangeBogiesType4,
			4: FinalStepType4,
		},
	},
	fifth: {
		stepsList: [
			'PRESSION CE et XCE',
			'Bogies alimenté(s)',
			'Bogies débloqué(s)',
			'Bogies bloqué(s)',
		],
		lines: ['1', '6'],
		steps: {
			0: PressionType5,
			1: PoweredBogiesType5,
			2: UnlockedBogiesType5,
			3: LockedBogiesType5,
			4: FinalStepType5,
		},
	},
};

export const initFormatData = {
	first: {
		cePressions: Array(1).fill(null),
		redRailcars: Array(5).fill(false),
		redXrFr: Array(5).fill(false),
		nonOrangeBogies: Array(10).fill(false),
		checkCounter: null,
	},
	second: {
		cePressions: Array(2).fill(null),
		closedXce: Array(8).fill(false),
		poweredBogies: Array(5).fill(true),
		unlockedBogies: Array(5).fill(false),
		closedXf: Array(5).fill(false),
		checkCounter: null,
	},
	third: {
		cePressions: Array(2).fill(null),
		closedXce: Array(4).fill(false),
		closedXf: Array(6).fill(false),
		unlockedBogies: Array(6).fill(false),
		checkCounter: null,
	},
	fourth: {
		cePressions: Array(1).fill(null),
		redRailcars: Array(10).fill(false),
		redXrFr: Array(10).fill(false),
		nonOrangeBogies: Array(10).fill(false),
		checkCounter: null,
	},
	fourthLong: {
		cePressions: Array(1).fill(null),
		redRailcars: Array(16).fill(false),
		redXrFr: Array(16).fill(false),
		nonOrangeBogies: Array(16).fill(false),
		checkCounter: null,
	},
	fifth: {
		cePressions: Array(2).fill(null),
		closedXce: Array(10).fill(false),
		poweredBogies: Array(12).fill(false),
		unlockedBogies: Array(12).fill(false),
		lockedBogies: Array(12).fill(false),
		checkCounter: null,
	},
};
