import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TotalCount from '../components/total-count/total-count';

import PreviousResult from './previous-result/previous-result';
import ResumeCheckBoxes from './resume-checkboxes/resume-checkboxes';

const FinalStepType3 = ({
	formData,
	handleChange,
	formatType,
	isEndState,
	returnFirstStep,
	setValidCheckbox,
}) => {
	const { t } = useTranslation();
	const countChecked = formData.unlockedBogies.filter((item) => item === true).length;

	useEffect(() => {
		setValidCheckbox(countChecked);
	}, [countChecked, setValidCheckbox]);

	return (
		<div className="content__final__step">
			<PreviousResult
				pressionValues={formData.cePressions}
				returnFirstStep={returnFirstStep}
				formData={formData}
				formatType={formatType}
			/>
			<ResumeCheckBoxes
				updatedResultCheck={formData.unlockedBogies}
				formatType={formatType}
				isEndState={isEndState}
			/>
			<div className="divider" />
			<TotalCount
				checkCounter={formData.checkCounter}
				handleChange={handleChange}
				isLarge
				subtitle={t('result-sheet:popup.result-form.final-step.type3.step5-title')}
				TotalCountText={t('result-sheet:popup.result-form.final-step.total-count-axle')}
			/>
		</div>
	);
};

export default FinalStepType3;

FinalStepType3.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	returnFirstStep: PropTypes.number.isRequired,
	formatType: PropTypes.object.isRequired,
	isEndState: PropTypes.bool.isRequired,
	setValidCheckbox: PropTypes.func.isRequired,
};
