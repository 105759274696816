import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import './sl-train-search.scss';

import { searchBinder } from '../../../../domains/binder/binder.services';
import { usePopup, useRailwayOnlineReturn, useSprContext } from '../../../../shared';
import CardBlockTemplate from '../../../../shared/components/card-block-template/card-block-template';
import { useAppSetupContext } from '../../../../shared/context/app-setup-context/app-setup-context';
import { IconWrapper, SprPage, TextfieldAutocomplete } from '../../../../theme';
import { IconSearch } from '../../../../theme/assets/img';
import { sortAndGroupBinderByMaterial } from '../../../binder/utils/binder-list-utils';
import { binderTrainTypeList } from '../../../binder/utils/binder-type';
import { SprInProgressProcessList } from '../../../process';
import { findSheetByBinderTechId } from '../../../sheet/sheet.services';
import { ReactComponent as IconFormation } from '../../assets/icon-formation.svg';
import { ReactComponent as IconTrain } from '../../assets/icon-train.svg';
import PopupCacheLineInfo from '../../components/popup-cache-line-info/popup-cache-line-info';
import { fetchTrain } from '../../train.services';

import PopupWarning from './components/popup-warning/popup-warning';

/**
 * Render the train-agent home-page
 * @return {JSX.Element}
 * @constructor
 */
const SlTrainSearch = () => {
	const { t } = useTranslation();
	const browserHistory = useHistory();
	const location = useLocation();
	const { associatedLine, resetCurrentBinder } = useSprContext();
	const [query, setQuery] = useState('');
	const cacheLinePopupControl = usePopup();
	const trainWarningControl = usePopup();
	const { user = {} } = useAppSetupContext();
	const [materialList, setMaterialList] = useState([]);
	const [sheetDataMap, setSheetDataMap] = useState({});

	const { role } = user;

	useRailwayOnlineReturn(true);

	const onSelect = (selected) => {
		const { value: train } = selected;
		const {
			binder_auto: binderAuto,
			binder_driver: binderDriver,
			binder_officer: binderOfficer,
		} = train;

		const binderCount = !!binderAuto + !!binderDriver + !!binderOfficer;
		if (binderCount === 1) {
			const binderId = encodeURIComponent(`${binderAuto || binderDriver || binderOfficer}`);
			browserHistory.push(`/railway/train/${train?.tech_id}/binder/${binderId}`);
		} else if (binderCount > 1) {
			browserHistory.push(`/railway/train/${train?.tech_id}/binders`);
		} else {
			browserHistory.push('/404');
		}
	};

	const onChange = (e) => {
		setQuery(e.target.value);
	};

	const handleGetSuggestions = async (querySearch) => {
		const response = await fetchTrain(querySearch, { line: associatedLine, is_active: true });

		return response?.data?.map((train) => ({
			label: { className: 'search__suggestion', lines: [{ type: 'label', text: train.id }] },
			value: train,
		}));
	};

	const showPopupByQueryParam = () => {
		const q = qs.parse(location.search, { ignoreQueryPrefix: true });
		if (q.showLineStatus) {
			cacheLinePopupControl.show();
		}
	};

	useEffect(() => {
		const fetchBinders = async () => {
			try {
				if (associatedLine) {
					const params = {
						associatedLine,
						status: 'published',
						type: binderTrainTypeList,
					};
					const { data: binders = [] } = await searchBinder(params);
					setMaterialList(sortAndGroupBinderByMaterial(binders));
				}
			} catch (error) {
				console.error(error);
			}
		};

		fetchBinders();
	}, [associatedLine]);

	useEffect(() => {
		const fetchSheetData = async () => {
			if (materialList.length > 0) {
				const results = await Promise.all(
					materialList.map(async (material) => {
						const [materialTechId, binderList] = material;
						const [binder] = binderList;
						const { tech_id } = binder;
						const sheetData = await findSheetByBinderTechId(tech_id);
						return { tech_id, sheetData, materialTechId };
					})
				);

				const newSheetDataMap = results.reduce((acc, { tech_id, sheetData }) => {
					acc[tech_id] = sheetData;
					return acc;
				}, {});

				setSheetDataMap(newSheetDataMap);
			}
		};

		fetchSheetData();
	}, [materialList]);

	useEffect(resetCurrentBinder, [resetCurrentBinder]);
	useEffect(showPopupByQueryParam, [location, cacheLinePopupControl]);

	const renderMaterialList = () => {
		return materialList.map((material) => {
			const [materialTechId, binderList] = material;
			const [binder] = binderList;
			const { material_label: materialLabel, color, tech_id } = binder;
			const sheetData = sheetDataMap[tech_id];

			const sheetNumber =
				sheetData && sheetData.data && sheetData.data[0] ? sheetData.data[0].number : null;

			const uri = sheetNumber
				? `/railway/process/run?binderTechnicalId=${tech_id}&sheetNumber=${sheetNumber}&materialTechId=${materialTechId}`
				: '/404';

			return (
				<div key={materialTechId}>
					<CardBlockTemplate linkTo={uri} mainText={materialLabel} color={color} />
				</div>
			);
		});
	};
	return (
		<SprPage className="sl-train-search" allowLineSwitch>
			<PopupWarning popupControl={trainWarningControl} />
			<PopupCacheLineInfo popupControl={cacheLinePopupControl} />
			<SprInProgressProcessList />
			<div className="sl-train-search__wrapper">
				<IconWrapper className="sl-train-search__train-image" Component={IconTrain} />
				<p className="sl-train-search__text">
					{role === 'train-driver'
						? t('train:sl-train-search.text-driver')
						: t('train:sl-train-search.text')}
				</p>
				{role !== 'train-driver' ? (
					<div className="sl-train-search__search">
						<TextfieldAutocomplete
							getSuggestions={handleGetSuggestions}
							className="search__field"
							inputClassName="search__input"
							value={query}
							handleChange={onChange}
							label={t('train:sl-train-search.search-label')}
							icon={false}
							selectedValue={onSelect}
						/>
						<IconWrapper className="search__icon" Component={IconSearch} />
					</div>
				) : (
					renderMaterialList()
				)}
				{role !== 'train-driver' ? (
					<Link className="sl-train-search__help-link" to="/railway/binders">
						{t('train:sl-train-search.help-link')}
					</Link>
				) : (
					<div className="sl-train-search__help_train_driver">
						{t('train:sl-train-search.help-driver')}
					</div>
				)}

				{role === 'train-driver' && (
					<div className="sl-train-search__formation">
						<IconWrapper className="sl-train-formation-image" Component={IconFormation} />
						{t('train:sl-train-search.help-formation')}
						<a
							className="sl-train-search__formation_link"
							href="https://digiproc-formation.ratp.io/"
						>
							{t('train:sl-train-search.help-formation-link')}
						</a>
					</div>
				)}
			</div>
		</SprPage>
	);
};

export default SlTrainSearch;
