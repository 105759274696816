import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ScreenType5 from '../../components/screen-type5/screen-type5';

const PoweredBogiesType5 = ({ formData, handleChange, handleValidate }) => {
	const { t } = useTranslation();
	return (
		<ScreenType5
			title={t('result-sheet:popup.result-form.step.type5.powered-bogies')}
			bogies={formData.poweredBogies}
			bogiesName="poweredBogies"
			handleChange={handleChange}
			handleValidate={handleValidate}
			validateButtonText={t('result-sheet:popup.result-form.validate')}
		/>
	);
};

export default PoweredBogiesType5;

PoweredBogiesType5.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
};
