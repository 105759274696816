import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import { TextField } from '../../../../../../../theme';
const InfoPression = ({ disabled, sheetType, handleChange, value, index }) => (
	<div className={`schema__pressure-box ${sheetType}`}>
		<TextField
			name="cePressions"
			value={value === 0 ? '0' : value}
			inputMode="numeric"
			pattern="[0-9]*"
			label="Pression CE (KPa)"
			type="number"
			onChange={(event) => handleChange(event, index)}
			tabIndex={0}
			disabled={disabled}
		/>
	</div>
);

export default InfoPression;

InfoPression.propTypes = {
	disabled: PropTypes.bool,
	handleChange: PropTypes.func,
	index: PropTypes.number.isRequired,
	sheetType: PropTypes.string,
	value: PropTypes.number.isRequired,
};
