import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import { Button } from '../../../../../../../theme';

const CloseXfStepType3 = ({ formData, handleChange, handleValidate }) => {
	const { t } = useTranslation();

	return (
		<div className="content__steps">
			<div className="content__schema__step__3">
				<h3 className="content__subtitle">{t('result-sheet:popup.result-form.close-xf-title3')}</h3>
				<div className="schema__base-schema-steps-type3">
					<div className="schema__checkbox-container-steps-type3">
						{formData.closedXf.map((checked, index) => (
							<div key={index} className={`railcar-container-steps-type3 `}>
								<label className="checkbox-steps-type2">
									<input
										type="checkbox"
										name="closedXf"
										checked={checked}
										onChange={(event) => handleChange(event, index)}
									/>
									<span className="checkbox-custom-steps-type2"></span>
								</label>
							</div>
						))}
						<div className="head-train-type3"></div>
					</div>
				</div>
			</div>
			<div className="content__actions">
				<Button
					variant="primary"
					onClick={handleValidate}
					label={t('result-sheet:popup.result-form.validate')}
					style={{ height: '6vh', fontSize: '21px' }}
				/>
			</div>
		</div>
	);
};

export default CloseXfStepType3;

CloseXfStepType3.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
};
