import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import { Button } from '../../../../../../../theme';
import PairRoundedCheckbox from '../pair-rounded-checkbox/pair-rounded-checkbox';
import RailcarType5 from '../railcar-type5/railcar-type5';
const ScreenType5 = ({
	title,
	subtitle,
	bogies,
	bogiesName,
	handleChange,
	handleValidate,
	validateButtonText,
	withSpan,
}) => {
	const railcarTitles = Array(6).fill('V');
	return (
		<>
			<div className="railcar-title-type5">
				{railcarTitles.map((title, index) => (
					<h3 key={index}>{`${title}${index + 1}`}</h3>
				))}
			</div>
			<RailcarType5 />
			<div className={`subtitle-container ${withSpan ? 'with-span' : ''}`}>
				<h3 className="content__subtitle">{title}</h3>
				{withSpan && <span>{subtitle}</span>}
			</div>
			<div className="type5-bogies-container">
				{bogies.map((__checked, index) => {
					return index % 2 === 0 ? (
						<PairRoundedCheckbox
							key={index}
							checkedArray={bogies}
							handleChange={handleChange}
							index={index}
							name={bogiesName}
						/>
					) : null;
				})}
			</div>
			<div className="content__actions">
				<Button
					variant="primary"
					onClick={handleValidate}
					label={validateButtonText}
					style={{ height: '6vh', fontSize: '21px' }}
				/>
			</div>
		</>
	);
};

export default ScreenType5;

ScreenType5.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	bogies: PropTypes.arrayOf(PropTypes.bool).isRequired,
	bogiesName: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
	validateButtonText: PropTypes.string.isRequired,
	withSpan: PropTypes.bool,
};
