/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import './user-home.scss';

import { LoadWrapper, useAppSetupContext, useSprContext } from '../../../../shared';
import { CdrPage, IconWrapper, SprPage } from '../../../../theme';
import { CdrHomeIcon, SprHomeIcon } from '../../../../theme/assets/img';
import { iconLines } from '../../utils/associated-line-icon-utils/associated-line-icon.utils';
import useRoleType from '../../utils/use-role-type';
import { setupLineScopeAllowed } from '../../utils/user-associated-line-scope';

const sprBinderPath = '/spr/binders';
const cdrBinderPath = '/binders';
const railwayTrainSearchPath = '/railway';

const UserHome = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { user, online } = useAppSetupContext();

	const { setStoredAssociatedLine, setSide, side: contextSide } = useSprContext();
	const { role } = user;
	const {
		isLineOperatorRole,
		isTrainAgentRole,
		isReglementationRole,
		isMainMenuRole,
		isTrainDriverRole,
	} = useRoleType(role);
	const accessRailwayRolesList = ['train-agent', 'train-driver', 'regulation-rer'];

	const generateLink = (url, icon, textKey, action = () => {}, line = '', lineIcon = '') => {
		if (lineIcon) {
			return (
				<li className="user-homepage__link" key={textKey + line} onClick={action}>
					<IconWrapper Component={lineIcon} className="user-homepage__link__line-icon" />
				</li>
			);
		} else {
			return (
				<li className="user-homepage__link" key={textKey + line} onClick={action}>
					<IconWrapper Component={icon} />
					<div className="user-homepage__link-title">
						{t(textKey)}
						{line && `${t('user:homepage.line')} ${line}`}
					</div>
				</li>
			);
		}
	};

	const goToSpecficLine = (line, options = {}) => {
		const { instant = true, side = '', redirectUri = '#' } = options;
		if (instant) {
			setSide(side);
			if (online) setStoredAssociatedLine(line);
			history.push(redirectUri);
		} else {
			return () => {
				setSide(side);
				if (online) setStoredAssociatedLine(line);
				setTimeout(() => history.push(redirectUri), 100);
			};
		}
	};

	const allLines = setupLineScopeAllowed(user);

	const linesLink = allLines.map((line) => {
		const url = accessRailwayRolesList.includes(role) ? railwayTrainSearchPath : sprBinderPath;
		const action = goToSpecficLine(line, { instant: false, side: 'pcc', redirectUri: url });
		return generateLink(
			url,
			SprHomeIcon,
			'user:homepage.automated-line',
			action,
			line,
			iconLines[line]
		);
	});

	const lineOperatorRedirect = (userAssociatedLine) => {
		const userHasMultipleLines = userAssociatedLine?.length > 1;
		const isMobile = window.screen.width <= 360;

		if (userHasMultipleLines) {
			return linesLink;
		} else if (isMobile) {
			goToSpecficLine(userAssociatedLine[0], {
				instant: true,
				side: 'train',
				redirectUri: railwayTrainSearchPath,
			});
		} else {
			goToSpecficLine(userAssociatedLine[0], {
				instant: true,
				side: 'pcc',
				redirectUri: sprBinderPath,
			});
		}
	};

	const getRoleLinks = (userData = {}) => {
		const { associated_line: userAssociatedLine, main_line: mainLine } = userData;

		if (isLineOperatorRole) {
			return lineOperatorRedirect(userAssociatedLine);
		}
		if (isMainMenuRole) {
			return linesLink;
		}
		if (isTrainAgentRole || isTrainDriverRole) {
			goToSpecficLine(mainLine, {
				instant: true,
				side: 'train',
				redirectUri: railwayTrainSearchPath,
			});
		} else if (isReglementationRole) {
			history.push(cdrBinderPath);
		} else {
			return [];
		}
	};

	const links = getRoleLinks(user);

	const homePageContent = () => {
		const Side = ({ title, subtitle, children, className }) => (
			<div className={classNames('user-homepage__side', className)}>
				<div className="user-homepage__side__header">
					<h2 className="user-homepage__side__title">{title}</h2>
					<span className="user-homepage__side__subtitle">{subtitle}</span>
				</div>
				{children}
			</div>
		);
		return (
			<nav
				className={classNames('user-homepage__nav', {
					'user-homepage__nav--admin': isMainMenuRole,
				})}
			>
				{isMainMenuRole && (
					<Side
						title={t('user:homepage.regulation-title')}
						subtitle={t('user:homepage.regulation-subtitle')}
						className="user-homepage__side--regulation"
					>
						{generateLink(cdrBinderPath, CdrHomeIcon, 'user:homepage.regulation', () =>
							history.push(cdrBinderPath)
						)}
					</Side>
				)}
				<Side
					title={t('user:homepage.line-title')}
					subtitle={t('user:homepage.line-subtitle')}
					className="user-homepage__side--lines"
				>
					<div className="user-homepage__links__wrapper">
						<ul
							className={classNames('user-homepage__links', {
								'user-homepage__links--one-row': links?.length === 5,
							})}
						>
							{links}
						</ul>
					</div>
				</Side>
			</nav>
		);
	};

	const renderHomePageFunctionRole = (content) => {
		if (isLineOperatorRole) {
			return (
				<SprPage className="user-homepage" mainInfosNotVisible>
					{content}
				</SprPage>
			);
		} else {
			return <CdrPage className="user-homepage">{content}</CdrPage>;
		}
	};

	useEffect(() => {
		if (!contextSide) {
			setSide(isTrainAgentRole ? 'train' : 'pcc');
		}
	}, [isTrainAgentRole, contextSide, setSide]);

	return renderHomePageFunctionRole(homePageContent());
};

const UserHomeWrapper = (props) => {
	const { user } = useAppSetupContext();
	const noUser = Object.keys(user || {}).length === 0;

	return (
		<LoadWrapper loading={noUser} className="loadwrapper--center">
			<UserHome {...props} />
		</LoadWrapper>
	);
};

export default UserHomeWrapper;
