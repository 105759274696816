import React from 'react';
import PropTypes from 'prop-types';

import '../styles.scss';
const RedXrFrCheckbox = ({ checked, redRailCarChecked, handleChange, index }) => {
	return (
		<div className="container">
			<div
				className={`top-bar disabled-styled ${redRailCarChecked ? 'border-secondary' : 'border-grey'}`}
			>
				<div className="square-xr" />
			</div>
			<div
				className={`middle-bar border-top-grey disabled-styled ${redRailCarChecked ? 'border-side-secondary' : 'border-side-grey'}`}
			/>
			<div className="middle-bar disabled-styled" />
			<div className={`circle ${checked ? 'border-secondary' : 'border-primary'}`}>
				<input
					checked={checked}
					name="redXrFr"
					onChange={(event) => handleChange(event, index)}
					type="checkbox"
					style={{ accentColor: '#0022c0', width: '20px', height: '20px' }}
				/>
			</div>
		</div>
	);
};

export default RedXrFrCheckbox;

RedXrFrCheckbox.propTypes = {
	checked: PropTypes.bool.isRequired,
	redRailCarChecked: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
};
