import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import ScreenType5 from '../components/screen-type5/screen-type5';

const UnlockedBogiesType5 = ({ formData, handleChange, handleValidate }) => {
	const { t } = useTranslation();
	return (
		<ScreenType5
			bogies={formData.unlockedBogies}
			bogiesName="unlockedBogies"
			handleChange={handleChange}
			handleValidate={handleValidate}
			title={t('result-sheet:popup.result-form.step.type5.unlocked-bogies')}
			subtitle={t('result-sheet:popup.result-form.step.type5.unlocked-bogies-subtitle')}
			validateButtonText={t('result-sheet:popup.result-form.validate')}
			withSpan={true}
		/>
	);
};

export default UnlockedBogiesType5;

UnlockedBogiesType5.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
};
