import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import { TextField } from '../../../../../../../../theme';
const TotalCount = ({ checkCounter, handleChange, isLarge, subtitle, TotalCountText }) => {
	const { t } = useTranslation();

	return (
		<div className="total-count-container">
			<div className={isLarge ? 'large-marged' : 'quart-marged'}>
				<h3 className={`${isLarge ? 'text-large' : ''}`}>{subtitle}</h3>
			</div>
			<h3>{t('result-sheet:popup.result-form.first-inactive-railcar')}</h3>
			<div className="textfield-total-count">
				<TextField
					name="checkCounter"
					label={t('result-sheet:popup.result-form.final-step.label')}
					onChange={(event) => handleChange(event)}
					type="number"
					inputMode="numeric"
					pattern="[0-9]*"
					value={checkCounter === 0 ? '0' : checkCounter}
				/>
			</div>
			<h3>{TotalCountText}</h3>
		</div>
	);
};

export default TotalCount;

TotalCount.propTypes = {
	checkCounter: PropTypes.number,
	handleChange: PropTypes.func.isRequired,
	isLarge: PropTypes.bool,
	subtitle: PropTypes.string.isRequired,
	TotalCountText: PropTypes.string.isRequired,
};
