import formUserRoleConfig from '../pages/admin-user-list/components/form-user/form-user-role-config';

const userMetroScope = [
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'11',
	'12',
	'13',
	'14',
	'0',
	'VA',
];
const userRerScope = ['A', 'B'];
const userLineScope = userMetroScope.concat(userRerScope);

const setupLineScopeAllowed = (user = {}) => {
	const { associated_line: associatedLineUser = [], role: userRole, origin } = user;
	if (associatedLineUser?.length > 0 && !hasAllLines(user) && userRole !== 'administrator') {
		return associatedLineUser;
	} else if (origin === 'mts' && userRole !== 'administrator') {
		return userMetroScope;
	} else if (origin === 'rer') {
		return userRerScope;
	}
	return userLineScope;
};

// for line-operator which exists for both mts and rer, define linesAllowed based on the user's origin
// else return linesAllowed from formUserRoleConfig
const defineLinesScopeByRole = (currentUser, role) => {
	let linesAllowed = [];
	if (currentUser?.origin === 'mts' && role === 'line-operator') {
		linesAllowed = userMetroScope;
	} else if (currentUser?.origin === 'rer' && role === 'line-operator') {
		linesAllowed = userRerScope;
	} else {
		linesAllowed = formUserRoleConfig[role]?.linesAllowed;
	}
	return linesAllowed;
};

const hasAllLines = (newUser, currentUser = {}) => {
	const { role = '', associated_line: associatedLine = [] } = newUser || {};
	const linesAllowed = defineLinesScopeByRole(currentUser, role);
	return linesAllowed?.every((line) => associatedLine?.includes(line));
};

export {
	defineLinesScopeByRole,
	hasAllLines,
	setupLineScopeAllowed,
	userLineScope,
	userMetroScope,
	userRerScope,
};
