import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import { PopupWrapper } from '../../../../shared';
import { Button } from '../../../../theme';

import Stepper from './stepper/stepper';
import useResultForm from './utils/use-result-sheet-form';
import FormSteps from './steps';

const ResultForm = ({ line, popupControl, processTechId, materialLabel }) => {
	const { t } = useTranslation();
	const {
		setActiveStep,
		handleNextStep,
		activeStep,
		formData,
		handleChange,
		formatType,
		returnFirstStep,
		handleSubmit,
		validCheckbox,
		setValidCheckbox,
	} = useResultForm(line, popupControl.visible, processTechId, materialLabel);

	const handleClose = () => {
		popupControl.setVisible(false);
	};

	if (!formatType) return null;

	const finalStep = activeStep === formatType.stepsList.length;

	const validateCheckBoxValues = Number(formData.checkCounter) === Number(validCheckbox);

	return (
		<PopupWrapper visible={popupControl.visible}>
			<div className="result-form">
				<h2 className="result-form__title">{t('result-sheet:popup.result-form.title')}</h2>
				{!finalStep && (
					<Stepper
						stepsList={formatType.stepsList}
						setActiveStep={setActiveStep}
						activeStep={activeStep}
					/>
				)}
				<FormSteps
					activeStep={activeStep}
					formatType={formatType}
					formData={formData}
					handleValidate={handleNextStep}
					handleChange={handleChange}
					returnFirstStep={returnFirstStep}
					setValidCheckbox={setValidCheckbox}
				/>
				<div className="result-form__actions">
					<Button
						variant="secondary"
						onClick={handleClose}
						label={t('result-sheet:popup.result-form.cancel')}
						style={{ height: '5vh', fontSize: '20px' }}
					/>
					{finalStep && (
						<Button
							disabled={!validateCheckBoxValues}
							variant="primary"
							onClick={() => handleSubmit(handleClose)}
							label={t('result-sheet:popup.result-form.final-step.validate')}
							style={{ height: '5vh', fontSize: '20px' }}
						/>
					)}
				</div>
			</div>
		</PopupWrapper>
	);
};

export default ResultForm;

ResultForm.propTypes = {
	line: PropTypes.string.isRequired,
	popupControl: PropTypes.object.isRequired,
	processTechId: PropTypes.string,
	materialLabel: PropTypes.string,
};
