import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import PreviousResultHeader from '../../components/previous-result-header/header/header';

const PreviousResult = ({ pressionValues, returnFirstStep, formData }) => {
	const { t } = useTranslation();

	return (
		<div className="content__schema__final">
			<PreviousResultHeader returnFirstStep={returnFirstStep} />

			<div className="schema__base-schema__final_pression">
				{pressionValues.map((pression, index) => (
					<div key={index} className="content__result_pression">
						{t('result-sheet:popup.result-form.title-step-pression')}
						<div className="result_pression">{`${pression} Kpa`}</div>
					</div>
				))}
			</div>
			<div className="schema__checkbox-container_final_type3">
				{formData.closedXce.map((checked, index) => (
					<div key={index} className="railcar-container-type3-final-step">
						<label key={index} className="checkbox-pression">
							<input type="checkbox" name="closedXce" checked={checked} />
							<span className="checkbox-custom"></span>
						</label>
					</div>
				))}
			</div>
			<div className="schema__checkbox-container-final-type3">
				<h3 className="content__subtitle_final_step_type3">
					{t('result-sheet:popup.result-form.final-step.close-xf')}
				</h3>
				{formData.closedXf.map((checked, index) => (
					<div key={index} className={`railcar-container-final-type3`}>
						<label className="checkbox-steps-type2">
							<input type="checkbox" name="closedXf" checked={checked} />
							<span className="checkbox-custom-steps-type2"></span>
						</label>
					</div>
				))}
				<div className="head-train-type3"></div>
			</div>
			<div className="schema__checkbox-container_final_essieu_type3">
				<h3 className="content__subtitle_final_step_type3">
					{t('result-sheet:popup.result-form.final-step.essieu-blocked')}
				</h3>
				{formData.unlockedBogies.map((checked, index) => (
					<div key={index} className={`railcar-container-final-type3`}>
						<label className="checkbox-steps-type2">
							<input type="checkbox" name="unlockedBogies" checked={checked} />
							<span className="checkbox-custom-steps-type2"></span>
						</label>
					</div>
				))}
				<div className="head-train-type3"></div>
			</div>

			<div className="schema__base-schema__final_check"></div>
		</div>
	);
};

export default PreviousResult;

PreviousResult.propTypes = {
	formData: PropTypes.array.isRequired,
	pressionValues: PropTypes.array.isRequired,
	returnFirstStep: PropTypes.number.isRequired,
	formatType: PropTypes.string.isRequired,
};
