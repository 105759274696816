import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const CallPCC = () => {
	const { t } = useTranslation();
	return (
		<div className="content__call-pc">
			<h3 className="content__subtitle__title-inactive-railcar">
				{t('result-sheet:popup.result-form.title-inactive-railcar')}
			</h3>
			<div className="raw"></div>
			<h3 className="content__subtitle__call__pcc">
				{t('result-sheet:popup.result-form.call-pcc')}
			</h3>
		</div>
	);
};

export default CallPCC;
