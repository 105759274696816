import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-warning.scss';

import { useAppEnv } from '../../../../../../config/env/app-env';
import { useAppSetupContext } from '../../../../../../shared';
import { Button, PopupDefault } from '../../../../../../theme';
import useRoleType from '../../../../../user/utils/use-role-type';

const PopupWarning = ({ popupControl }) => {
	const { t } = useTranslation();
	const env = useAppEnv();
	const { user } = useAppSetupContext();
	const { role } = user;
	const { isTrainDriverRole } = useRoleType(role);

	useEffect(() => {
		if (isTrainDriverRole && env.REACT_APP_ENV === 'formation') {
			popupControl.show();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [env.REACT_APP_ENV, isTrainDriverRole]);

	return (
		<PopupDefault popupControl={popupControl} title={`${t('user:popup.warning-training.title')}`}>
			<div className="content">{`${t('user:popup.warning-training.text1')}`}</div>
			<div className="content">
				{`${t('user:popup.warning-training.text2')}`}
				<div className="button">
					<Button
						label={`${t('user:popup.warning-training.buttonText')}`}
						onClick={() => popupControl.hide()}
					/>
				</div>
			</div>
		</PopupDefault>
	);
};

export default PopupWarning;

PopupWarning.propTypes = {
	popupControl: PropTypes.object.isRequired,
};
