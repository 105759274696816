import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './popup-binder-edit-title.scss';

import { checkFormError } from '../../../../../shared';
import { ErrorMessage, PopupConfirm } from '../../../../../theme';
import { fetchBinderListById, updateBinder } from '../../../binder.services';
import {
	getFormBinderFieldMessageKey,
	validatePopupEditBinderForm,
} from '../../../utils/validate-popup-binder-form';
import FormBinder from '../form-binder/form-binder';

/**
 * Render the PopupBinderEditTitle component
 * @param {object} props
 * @param {function} props.onSubmitSuccess Callback called after successful submit
 * @param {object} props.binder The current binder information
 * @param {object} props.popupControl
 * @param {object} props.binderTrain Boolean to indicate if current binder is type of train
 * @returns {JSX.Element}
 */
const PopupBinderEditTitle = (props) => {
	const { onSubmitSuccess, binder, popupControl, binderTrain } = props;
	const { t } = useTranslation();

	const [formData, setFormData] = useState({});
	const [existingBinderList, setExistingBinderList] = useState([]);
	const [hasEditBinderId, setHasEditBinderId] = useState(false);
	const [networkError, setNetworkError] = useState(false);

	const getCurrentBinder = useCallback(
		(binderList) => binderList.find(({ tech_id }) => tech_id === binder.tech_id) || {},
		[binder.tech_id]
	);

	const formError = validatePopupEditBinderForm(
		formData,
		getCurrentBinder(existingBinderList),
		existingBinderList
	);
	const formErrorMessage = getFormBinderFieldMessageKey(formError);

	const fetchExistingBinderById = useCallback(
		async (binderId) => {
			if (!binderId) return Promise.resolve(null);

			return fetchBinderListById(binderId)
				.then((response) => {
					setNetworkError(false);
					const binderList = response?.data || [];
					setExistingBinderList(binderList);
					setHasEditBinderId(
						!!binderList.find(({ id, tech_id }) => binderId === id && binder.tech_id !== tech_id)
					);

					return getCurrentBinder(binderList);
				})
				.catch((error) => {
					console.error(error);
					setNetworkError(true);
					return null;
				});
		},
		[getCurrentBinder, binder.tech_id]
	);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleConfirm = () => {
		if (!checkFormError(formError)) {
			const {
				station: station_tech_id,
				stationType: station_type,
				lineNumber: associated_line,
				...binderData
			} = formData;
			updateBinder(binder?.tech_id, {
				...binderData,
				station_tech_id,
				station_type,
				associated_line,
			})
				.then((response) => {
					setNetworkError(false);
					onSubmitSuccess(response?.data);
				})
				.catch((error) => {
					console.error(error);
					setNetworkError(true);
				});
		}

		// Reset form after data's submission
		setFormData({});
	};

	const handleCancel = () => {
		setFormData({});
	};

	useEffect(() => {
		if (popupControl.visible && binder.id) {
			fetchExistingBinderById(binder.id).then((currentBinder) => {
				if (!currentBinder) return;

				setFormData((oldFormData) => ({
					...oldFormData,
					id: currentBinder.id,
					subtitle: currentBinder.subtitle,
					title: currentBinder.title,
					color: currentBinder.color,
					abbreviation: currentBinder.abbreviation,
					type: currentBinder.type,
					lineNumber: currentBinder?.associated_line || [],
					stationType: currentBinder.station_type,
					station: currentBinder.station_tech_id,
				}));
			});
		}
	}, [binder.id, popupControl.visible, fetchExistingBinderById]);

	useEffect(() => fetchExistingBinderById(formData.id), [formData.id, fetchExistingBinderById]);

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={
				binderTrain ? t('binder:popup.edit-binder-train.title') : t('binder:popup.edit-title.title')
			}
			className="popup-binder-edit-title__wrapper"
			onConfirm={handleConfirm}
			onClose={handleCancel}
			onCancel={handleCancel}
			ctaConfirmLabel={
				binderTrain
					? 'binder:popup.edit-binder-train.cta-confirm-label'
					: 'binder:popup.edit-title.cta-confirm-label'
			}
			ctaConfirmDisabled={checkFormError(formError)}
		>
			<form className="popup-binder-edit-title">
				<FormBinder
					form={formData}
					validation={formError}
					errorMessage={formErrorMessage}
					onChange={handleChange}
					update
					hasEditBinderId={hasEditBinderId}
					train={binderTrain}
				/>
				{networkError && (
					<ErrorMessage className="popup-binder-edit-title__network-error">
						{t('error.default')}
					</ErrorMessage>
				)}
			</form>
		</PopupConfirm>
	);
};

PopupBinderEditTitle.propTypes = {
	onSubmitSuccess: PropTypes.func,
	binder: PropTypes.shape({
		id: PropTypes.string,
		tech_id: PropTypes.string,
	}),
	popupControl: PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool,
	}),
	binderTrain: PropTypes.bool,
};

export default PopupBinderEditTitle;
