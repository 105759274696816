import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

const ResumeCheckboxes = ({ resumeCheckboxes, setResumeCheckboxes }) => {
	const { t } = useTranslation();

	const handleChange = useCallback(
		(index) => {
			const newResumeCheckboxes = [...resumeCheckboxes];
			newResumeCheckboxes[index] = !newResumeCheckboxes[index];
			setResumeCheckboxes(newResumeCheckboxes);
		},
		[resumeCheckboxes, setResumeCheckboxes]
	);

	return (
		<div className="resume-checboxes-type5">
			<h3 className="content__subtitle">
				{t('result-sheet:popup.result-form.final-step.type5.step5-title')}
			</h3>
			<div className="checkbox-line-container">
				<div className="raw-container">
					<div className="raw__type4" />
				</div>
				<div className="checkbox-container">
					{resumeCheckboxes.map((checked, index) => {
						return index % 2 === 0 ? (
							<div
								className={`pair-checkboxes ${index + 1 !== resumeCheckboxes.length - 1 ? 'border-right' : ''}`}
								key={index}
							>
								<div className="input-container">
									<input
										type="checkbox"
										checked={resumeCheckboxes[index]}
										onChange={() => handleChange(index)}
										style={{ accentColor: '#0022c0', width: '20px', height: '20px' }}
										aria-label={`Checkbox ${index + 1}`}
									/>
								</div>
								<div className="input-container">
									<input
										type="checkbox"
										checked={resumeCheckboxes[index + 1]}
										onChange={() => handleChange(index + 1)}
										style={{ accentColor: '#0022c0', width: '20px', height: '20px' }}
										aria-label={`Checkbox ${index + 2}`}
									/>
								</div>
							</div>
						) : null;
					})}
				</div>
			</div>
		</div>
	);
};

export default ResumeCheckboxes;

ResumeCheckboxes.propTypes = {
	resumeCheckboxes: PropTypes.arrayOf(PropTypes.bool).isRequired,
	setResumeCheckboxes: PropTypes.func.isRequired,
};
