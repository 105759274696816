import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TotalCount from '../components/total-count/total-count';

import PreviousResultType5 from './previous-result/previous-result';
import ResumeCheckboxes from './resume-checkboxes/resumecheckboxes';

const FinalStepType5 = ({ formData, handleChange, returnFirstStep, setValidCheckbox }) => {
	const { t } = useTranslation();
	const [resumeCheckboxes, setResumeCheckboxes] = useState(Array(12).fill(false));

	const countChecked = resumeCheckboxes.filter((item) => item === true).length;

	useEffect(() => {
		setValidCheckbox(countChecked);
	}, [countChecked, setValidCheckbox]);

	return (
		<>
			<PreviousResultType5 formData={formData} returnFirstStep={returnFirstStep} />
			<ResumeCheckboxes
				resumeCheckboxes={resumeCheckboxes}
				setResumeCheckboxes={setResumeCheckboxes}
			/>
			<TotalCount
				checkCounter={formData.checkCounter}
				handleChange={handleChange}
				isLarge={true}
				subtitle={t('result-sheet:popup.result-form.final-step.step6-title')}
				TotalCountText={t('result-sheet:popup.result-form.final-step.total-count-bogie')}
			/>
		</>
	);
};

export default FinalStepType5;

FinalStepType5.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	returnFirstStep: PropTypes.func.isRequired,
	setValidCheckbox: PropTypes.func.isRequired,
};
