import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
const RailcarXrType4 = ({ steps, formatType }) => (
	<div className={`car-content-type4__${steps} ${formatType.type}`}>
		<div className={`raw__xr__type4__steps`}></div>
		<div className="little-dark-rectangle-type4"></div>
		<div className={`car-container-xr-type4__${steps}`}>
			<div className="car-xr-type4">V1</div>
			<div className="car-join-xr-type4" />
			<div className={`car-join__${steps}`} />
			<div className="car-join-xr-type4" />
			<div className="car-xr-type4">V2</div>
			<div className="car-join-xr-type4" />
			<div className={`car-join__${steps}`} />
			<div className="car-join-xr-type4" />
			<div className="car-xr-type4">V3</div>
			<div className="car-join-xr-type4" />
			<div className={`car-join__${steps}`} />
			<div className="car-join-xr-type4" />
			<div className="car-xr-type4">V4</div>
			<div className="car-join-xr-type4" />
			<div className={`car-join__${steps}`} />
			<div className="car-join-xr-type4" />
			<div className="car-xr-type4">V5</div>
			{formatType.type === 'fourthLong' && (
				<>
					<div className="car-join-xr-type4" />
					<div className={`car-join__${steps}`} />
					<div className="car-join-xr-type4" />
					<div className="car-xr-type4">V6</div>
					<div className="car-join-xr-type4" />
					<div className={`car-join__${steps}`} />
					<div className="car-join-xr-type4" />
					<div className="car-xr-type4">V7</div>
					<div className="car-join-xr-type4" />
					<div className={`car-join__${steps}`} />
					<div className="car-join-xr-type4" />
					<div className="car-xr-type4">V8</div>
				</>
			)}
		</div>
	</div>
);

export default RailcarXrType4;

RailcarXrType4.propTypes = {
	steps: PropTypes.string.isRequired,
	formatType: PropTypes.string.isRequired,
};
