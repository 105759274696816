import userRoleMap from './user-role-map.json';

const {
	administrator,
	regulation,
	'line-operator': lineOperator,
	'line-operator-supervisor': lineOperatorSuperVisor,
	'train-agent': trainAgent,
	'train-driver': driverAgent,
	'admin-line': adminLine,
	'admin-regulation': adminRegulation,
	'admin-rer': adminRer,
	'regulation-rer': regulationRer,
} = userRoleMap;
const adminAccessRoles = [administrator, adminRegulation, adminLine, adminRer];
const advancedSprAccessRoles = [
	administrator,
	adminLine,
	lineOperatorSuperVisor,
	lineOperator,
	adminRer,
];
const sprAccessRoles = [...advancedSprAccessRoles, regulationRer];
const railwayAccessRoles = [...sprAccessRoles, trainAgent, driverAgent];
const binderManagementAccesRoles = [
	administrator,
	regulation,
	adminRegulation,
	adminRer,
	regulationRer,
];

export {
	adminAccessRoles,
	advancedSprAccessRoles,
	binderManagementAccesRoles,
	railwayAccessRoles,
	sprAccessRoles,
};
