import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import { Button } from '../../../../../../../theme';
import RailcarType1 from '../../components/railcar-type1/railcar-type1';
import InfoPression from '../info-pression/infoPression';

const PressionType1 = ({ formData, handleChange, handleValidate }) => {
	const { t } = useTranslation();
	const notCePressions = formData.cePressions.every((item) => item === null);
	return (
		<div className="content-container">
			<RailcarType1 />
			<h3 className="content__subtitle">
				{t('result-sheet:popup.result-form.step-pression-title1')}
			</h3>
			<div className="pressure-container">
				{formData.cePressions.map((value, index) => (
					<InfoPression
						key={`pression-value-${index}`}
						handleChange={handleChange}
						index={index}
						value={value}
					/>
				))}
			</div>
			<div className="content__actions">
				<Button
					variant="primary"
					onClick={handleValidate}
					label={t('result-sheet:popup.result-form.step-pressure-validate')}
					disabled={notCePressions}
					style={{ height: '5vh', fontSize: '20px' }}
				/>
			</div>
		</div>
	);
};

export default PressionType1;

PressionType1.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
};
