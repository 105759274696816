import React from 'react';
import { useTranslation } from 'react-i18next';

import ScreenType5 from '../../components/screen-type5/screen-type5';

const LockedBogiesType5 = ({ formData, handleChange, handleValidate }) => {
	const { t } = useTranslation();
	return (
		<ScreenType5
			bogies={formData.lockedBogies}
			bogiesName="lockedBogies"
			handleChange={handleChange}
			handleValidate={handleValidate}
			title={t('result-sheet:popup.result-form.step.type5.locked-bogies')}
			subtitle={t('result-sheet:popup.result-form.step.type5.locked-bogies-subtitle')}
			validateButtonText={t('result-sheet:popup.result-form.validate-to-final-step')}
			withSpan={true}
		/>
	);
};

export default LockedBogiesType5;
