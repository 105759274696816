import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import '../styles.scss';

import OrangeBogieCheckbox from '../../../components/orange-bogie-checkbox/orange-bogie-checkbox';

const Step5Type4 = ({ updatedResultCheck, formatType, isEndState }) => {
	const { t } = useTranslation();
	const type = `type4_final ${formatType.type}`;

	return (
		<div className="content__step5">
			<h3 className="content__subtitle">
				{t('result-sheet:popup.result-form.title_step-5-type4')}
			</h3>
			<div className="schema__checkbox-container-final-steps-type4">
				{updatedResultCheck.map((item, index) => {
					return (
						<OrangeBogieCheckbox
							key={index}
							checked={item.checked}
							index={index}
							type={type}
							isLast={index === updatedResultCheck.length - 1}
							isEndState={isEndState}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default Step5Type4;

Step5Type4.propTypes = {
	updatedResultCheck: PropTypes.array.isRequired,
	formatType: PropTypes.array.isRequired,
	isEndState: PropTypes.bool.isRequired,
};
