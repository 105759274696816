const userMetroScope = [
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'11',
	'12',
	'13',
	'14',
	'0',
	'VA',
];
const userRerScope = ['A', 'B'];
const userLineScope = [...userMetroScope, ...userRerScope];

/**
 * @typedef {Object} FormUserRoleDescription
 * @property {string[]} editableRole - editable role list by user with the described role
 * @property {boolean} allAssociatedLine - if true, described role have access to all line
 * @property {boolean} hasMainLine - if true, user must have a main line
 * @property {string[]} linesAllowed - list of lines allowed for this role
 */

/** @type {Record<string, FormUserRoleDescription>} */
const formUserRoleConfig = {
	administrator: {
		editableRole: [
			'administrator',
			'admin-regulation',
			'regulation',
			'admin-line',
			'line-operator-supervisor',
			'line-operator',
			'train-agent',
			// 'train-driver',
			'admin-rer',
			'regulation-rer',
		],
		allAssociatedLine: true,
		hasMainLine: false,
		linesAllowed: userLineScope,
	},
	'admin-regulation': {
		editableRole: ['regulation'],
		allAssociatedLine: false,
		hasMainLine: false,
		linesAllowed: userMetroScope,
	},
	regulation: {
		editableRole: [],
		allAssociatedLine: false,
		hasMainLine: false,
		linesAllowed: userMetroScope,
	},
	'admin-line': {
		editableRole: ['line-operator-supervisor', 'line-operator', 'train-agent', 'train-driver'],
		allAssociatedLine: false,
		hasMainLine: false,
		linesAllowed: userMetroScope,
	},
	'line-operator-supervisor': {
		editableRole: [],
		allAssociatedLine: false,
		hasMainLine: false,
		linesAllowed: userMetroScope,
	},
	'line-operator': {
		editableRole: [],
		allAssociatedLine: false,
		hasMainLine: false,
		linesAllowed: userLineScope,
	},
	'train-agent': {
		editableRole: [],
		allAssociatedLine: true,
		hasMainLine: true,
		linesAllowed: userMetroScope,
	},
	'train-driver': {
		editableRole: [],
		allAssociatedLine: false,
		hasMainLine: true,
		linesAllowed: userMetroScope,
	},
	'admin-rer': {
		editableRole: ['regulation-rer', 'line-operator', 'train-agent'],
		allAssociatedLine: true,
		hasMainLine: false,
		linesAllowed: userRerScope,
	},
	'regulation-rer': {
		editableRole: [],
		allAssociatedLine: false,
		hasMainLine: false,
		linesAllowed: userRerScope,
	},
};

export default formUserRoleConfig;
