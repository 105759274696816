import { useEffect, useState } from 'react';

import { postResultSheet } from '../../../result-sheet.service';

import { formatTypes, initFormatData } from './config';

const useResultForm = (line, isVisible, processTechId, materialLabel) => {
	const [formData, setFormData] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	const [formatType, setFormatType] = useState(null);
	const [validCheckbox, setValidCheckbox] = useState(null);

	const getFormatType = (line) => {
		for (const [key, value] of Object.entries(formatTypes)) {
			if (value.lines.includes(line)) {
				return { type: key, ...value };
			}
		}
		return null;
	};

	const handleCheck = (name, index) => {
		const newValues = [...formData[name]];
		newValues[index] = !newValues[index];
		setFormData((prev) => ({
			...prev,
			[name]: newValues,
		}));
	};

	const handleChangecePressions = (name, value, index) => {
		const newValues = [...formData.cePressions];
		newValues[index] = Number(value);
		setFormData((prev) => ({
			...prev,
			[name]: newValues,
		}));
	};

	const handleChangeInteger = (name, value) => {
		if (Number.isInteger(Number(value))) {
			setFormData((prev) => ({
				...prev,
				[name]: Number(value),
			}));
		}
	};

	const handleChange = (event, index) => {
		const { name, value } = event.target;
		if (
			name === 'closedXce' ||
			name === 'poweredBogies' ||
			name === 'unlockedBogies' ||
			name === 'closedXf' ||
			name === 'redRailcars' ||
			name === 'redXrFr' ||
			name === 'nonOrangeBogies' ||
			name === 'lockedBogies'
		)
			handleCheck(name, index);
		else if (name === 'cePressions') handleChangecePressions(name, value, index);
		else if (name === 'checkCounter') handleChangeInteger(name, value);
		else {
			setFormData((prev) => ({
				...prev,
				[name]: value,
			}));
		}
	};

	const returnFirstStep = () => {
		setActiveStep(0);
	};

	const handleSubmit = (callback) =>
		postResultSheet({ ...formData, materialLabel, line, processTechId }).then(() => callback());

	const handleNextStep = () => {
		setActiveStep((prev) => prev + 1);
	};

	useEffect(() => {
		if (isVisible) {
			const format = getFormatType(line);
			if (format?.type) {
				setFormatType(format);
				setFormData({ ...initFormatData[format.type], processTechId });
				setActiveStep(0);
			}
		}
	}, [line, isVisible, processTechId]);

	return {
		activeStep,
		setActiveStep,
		handleNextStep,
		formData,
		setFormData,
		handleChange,
		formatType,
		returnFirstStep,
		handleSubmit,
		validCheckbox,
		setValidCheckbox,
	};
};

export default useResultForm;
