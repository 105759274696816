import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './spr-header.scss';

import { offlineUser } from '../../../config/offline';
import { useRunProcessContext } from '../../../domains/process/components/context-run-process/context-run-process';
import PopupChangeOwner from '../../../domains/process/components/popup-change-owner/popup-change-owner';
import PopupCreateProcessTrain from '../../../domains/process/components/popup-create-process-train/popup-create-process-train';
import { updateProcess } from '../../../domains/process/process.services';
import { userRoleList } from '../../../domains/user';
import { iconLines } from '../../../domains/user/utils/associated-line-icon-utils/associated-line-icon.utils';
import { BurgerMenu, IconWrapper, LogoDigiproc } from '../../../theme';
import {
	IconBooks,
	IconFolder,
	IconHand,
	IconKey,
	IconProcessPcc,
	IconProcessTrain,
	IconRailwayOffline,
	IconSettings,
} from '../../../theme/assets/img';
import { usePopup } from '../..';
import { useAppSetupContext } from '../../context/app-setup-context/app-setup-context';
import { useSprContext } from '../../context/spr-context/spr-context';
import useInReadContextCheck from '../../custom-hook/use-in-read-context-check';
import ProfileIcon from '../profile-icon/profile-icon';

import LineSwitch from './components/line-switch/line-switch';
import SideSwitch from './components/side-switch/side-switch';
import SprHeaderDetail from './components/spr-header-detail/spr-header-detail';

const sprPrefix = '/spr';
const railwayPrefix = '/railway';
const processBaseUrl = '/process';

/**
 * SprHeader component
 * @param {object} props
 * @param {string} props.className
 * @param {boolean} props.binderAbbreviationNotVisible
 * @param {boolean} props.mainInfosNotVisible
 * @param {boolean} props.allowLineSwitch
 * @returns {JSX.Element}
 */
const SprHeader = (props) => {
	const {
		className = '',
		binderAbbreviationNotVisible = false,
		mainInfosNotVisible = false,
		allowLineSwitch = false,
		disabledLineSwitch = false,
	} = props;
	const { isInReadContext } = useInReadContextCheck();
	const { t } = useTranslation();
	const historyRoute = useHistory();
	const {
		trainLinksVisible,
		associatedLine,
		currentBinder,
		side,
		newInProgressProcess,
		fetchCurrentProcesses,
	} = useSprContext();
	const { user = {}, online } = useAppSetupContext();
	const { init, processInfo } = useRunProcessContext() || {};

	const { role } = user;

	const popupChangeOwnerControl = usePopup();
	const popupCreateProcessTrainControl = usePopup();

	const isCurrentUserProcessNotOwner =
		user?.tech_id !== undefined &&
		processInfo?.owner !== undefined &&
		user?.tech_id !== processInfo?.owner &&
		user?.tech_id !== offlineUser.tech_id;

	const urlPrefix = side === 'train' ? railwayPrefix : sprPrefix;
	const urlProcessHistory = `${urlPrefix}${processBaseUrl}`;
	const homePageLink =
		role === 'administrator' || role === 'regulation-rer' ? '/' : side === 'pcc' ? '/' : '/railway';
	const lineEntity = ['A', 'B'].includes(associatedLine) ? 'rer' : 'mts';

	const items = useMemo(
		() =>
			[
				{
					name: 'start-new-process-pcc',
					label: t('theme:spr-page.menu-burger.start-new-process-pcc'),
					link: '/spr/binders',
					visible: side !== 'train',
					authorizedRoles: userRoleList,
					icon: IconProcessPcc,
				},
				{
					name: 'start-new-process-train',
					label: t('theme:spr-page.menu-burger.start-new-process-train'),
					// stay on home page when on side train
					onClick: () =>
						side === 'train'
							? historyRoute.push('/railway')
							: popupCreateProcessTrainControl.show(),
					visible: trainLinksVisible,
					authorizedRoles:
						lineEntity === 'mts' ? userRoleList : userRoleList.filter((r) => r !== 'line-operator'),
					icon: IconProcessTrain,
				},
				{
					name: 'process-history',
					link: urlProcessHistory,
					label: t('theme:spr-page.menu-burger.process-history'),
					visible: true,
					authorizedRoles: userRoleList,
					icon: IconBooks,
				},
				{
					name: 'admin-page',
					label: t('theme:spr-page.menu-burger.admin-page'),
					onClick: () => historyRoute.push('/admin'),
					visible: side !== 'train',
					authorizedRoles: ['administrator', 'admin-line', 'admin-regulation', 'admin-rer'],
					icon: IconSettings,
				},
				{
					name: 'train-referential-page',
					label: t('theme:spr-page.menu-burger.train-referential-page'),
					onClick: () => historyRoute.push('/spr/referentiel-train'),
					visible: side !== 'train' && trainLinksVisible,
					authorizedRoles: [
						'administrator',
						'admin-line',
						'admin-regulation',
						'regulation',
						'line-operator-supervisor',
						'admin-rer',
					],
					icon: IconFolder,
				},
				{
					name: 'generate-opt-password',
					label: t('theme:spr-page.menu-burger.generate-password'),
					onClick: () => historyRoute.push('/totp/password'),
					visible: trainLinksVisible && side !== 'train',
					authorizedRoles: [
						'administrator',
						'admin-line',
						'line-operator-supervisor',
						'line-operator',
					],
					icon: IconKey,
				},
			].filter((item) => item.visible && item.authorizedRoles.includes(role)),
		[
			role,
			side,
			historyRoute,
			popupCreateProcessTrainControl,
			trainLinksVisible,
			t,
			urlProcessHistory,
			lineEntity,
		]
	);

	const changeOwner = () => {
		updateProcess(processInfo.tech_id, {}, { action: 'changeOwner' }).then(init);
	};

	const refreshInProgressProcess = () => {
		if (newInProgressProcess) {
			fetchCurrentProcesses();
			const intervalID = setInterval(fetchCurrentProcesses, 5000);
			return () => clearInterval(intervalID);
		}
	};

	// if we want the binder icon be visible and current binder infos are available => display the abbreviation/color binder in the header
	const displayCurrentBinderColor =
		!binderAbbreviationNotVisible &&
		currentBinder?.color &&
		(currentBinder?.abbreviation || currentBinder?.materialLabel);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(refreshInProgressProcess, [user, side, associatedLine]);

	return (
		<div className={classNames('spr-header', className)}>
			{popupCreateProcessTrainControl.visible && (
				<PopupCreateProcessTrain
					popupControl={popupCreateProcessTrainControl}
					line={associatedLine}
				/>
			)}
			<Link className="spr-header__logo" to={homePageLink}>
				<LogoDigiproc className="spr-header__logo__image" />
				<LogoDigiproc className="spr-header__logo__image--mobile" isMobile />
			</Link>
			{!mainInfosNotVisible ? (
				<>
					<span className={classNames('spr-header__separator spr-header__separator--start')} />
					<div className="spr-header__line">
						{side === 'train' && allowLineSwitch && <LineSwitch disabled={!!disabledLineSwitch} />}
						{(side === 'pcc' || !allowLineSwitch) && (
							<IconWrapper
								className="spr-header__line__icon"
								Component={iconLines[associatedLine]}
							/>
						)}
					</div>
					<SprHeaderDetail
						className="spr-header__details"
						displayCurrentBinderColor={displayCurrentBinderColor}
					/>
					{side === 'train' && !online && (
						<IconWrapper className="spr-header__offline" Component={IconRailwayOffline} />
					)}
					{isCurrentUserProcessNotOwner && !isInReadContext && processInfo.type !== 'train' && (
						<div className="process-actions-wrapper">
							<PopupChangeOwner popupControl={popupChangeOwnerControl} onConfirm={changeOwner} />
							<button
								className="process-actions-wrapper__owner"
								onClick={popupChangeOwnerControl.show}
							>
								<IconWrapper className="process-actions-wrapper--icon-hand" Component={IconHand} />
								{t('theme:spr-page.change-owner')}
							</button>
						</div>
					)}
					{trainLinksVisible && role !== 'train-driver' && (
						<SideSwitch className="spr-header__side" />
					)}
					{side === 'pcc' && (
						<span
							className={classNames(
								'spr-header__separator spr-header__separator--end spr-header__separator__end--pcc'
							)}
						/>
					)}
					{side === 'train' && (
						<span
							className={classNames('spr-header__separator spr-header__separator--end', {
								'spr-header__separator__end--train': online,
							})}
						/>
					)}
					<BurgerMenu
						items={items}
						processList={newInProgressProcess}
						role={role}
						processHistoryLink={urlProcessHistory}
					/>
				</>
			) : (
				<ProfileIcon />
			)}
		</div>
	);
};

SprHeader.propTypes = {
	className: PropTypes.string,
	binderAbbreviationNotVisible: PropTypes.bool,
	mainInfosNotVisible: PropTypes.bool,
	allowLineSwitch: PropTypes.bool,
	disabledLineSwitch: PropTypes.bool,
};

export default SprHeader;
