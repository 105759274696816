import React, { useEffect, useState } from 'react';
import { Editor as DraftJSEditor } from 'react-draft-wysiwyg';
import classNames from 'classnames';
import {
	convertFromRaw,
	convertToRaw,
	DefaultDraftBlockRenderMap,
	EditorState,
	Modifier,
} from 'draft-js';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import './editor.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { AlphabetListButton } from './customTools/alphaList';

const Editor = ({
	value,
	onChange = () => {},
	onBlur,
	name,
	label,
	toolbarConfig,
	disabled,
	className = '',
	focus,
}) => {
	const [editorState, setEditorState] = useState();

	const initWrapperClassName = (baseClassName) =>
		classNames(baseClassName, className, { [`${baseClassName}--disabled`]: disabled });

	const handleChange = (newEditorState) => {
		setEditorState(newEditorState);
		const currentRawContent = convertToRaw(newEditorState.getCurrentContent());
		const plainTextLength = currentRawContent.blocks[0]?.text.length;
		if (plainTextLength !== 0) {
			onChange({ target: { name, value: currentRawContent } });
		} else {
			onChange({ target: { name, value: null } });
		}
	};

	const initEditorContent = () => {
		// initialize editor content
		let newEditorState = EditorState.createEmpty();
		if (value) {
			const initialEditorState = convertFromRaw(value);
			newEditorState = EditorState.createWithContent(initialEditorState);
		}
		// handle cursor position on focus
		if (focus) {
			setEditorState(EditorState.moveFocusToEnd(newEditorState));
		} else {
			setEditorState(newEditorState);
		}
	};

	const editorConfig = {
		editorState,
		toolbar: toolbarConfig,
		wrapperClassName: initWrapperClassName('editor__wrapper'),
		editorClassName: 'editor',
		toolbarClassName: 'editor__toolbar',
		localization: {
			locale: 'fr',
		},
		onEditorStateChange: handleChange,
		onBlur,
	};

	const customBlockRenderMap = Map({
		'alpha-list-item': {
			element: 'li',
			wrapper: <ol className="public-DraftStyleDefault-alphaListItem" />,
			aliasedElements: ['li'],
		},
	});

	useEffect(initEditorContent, [value, focus, disabled]);
	const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(customBlockRenderMap);

	const handleReturn = (e) => {
		const contentState = editorState.getCurrentContent();
		const selection = editorState.getSelection();
		const currentBlock = contentState.getBlockForKey(selection.getStartKey());
		const currentType = currentBlock.getType();

		if (currentType === 'alpha-list-item') {
			e.preventDefault();

			const newContentState = Modifier.splitBlock(contentState, selection);
			const newState = EditorState.push(editorState, newContentState, 'split-block');

			const newSelection = newState.getSelection();
			const finalContentState = Modifier.setBlockType(
				newState.getCurrentContent(),
				newSelection,
				'alpha-list-item'
			);

			handleChange(EditorState.push(newState, finalContentState, 'change-block-type'));
			return true;
		}
		return false;
	};

	return (
		<DraftJSEditor
			{...editorConfig}
			readOnly={disabled}
			stripPastedStyles={true}
			placeholder={label}
			blockRenderMap={extendedBlockRenderMap}
			handleReturn={handleReturn}
			toolbarCustomButtons={[
				<AlphabetListButton
					editorState={editorState}
					handleChange={handleChange}
					key="alphabet-list"
				/>,
			]}
		/>
	);
};

export default Editor;

Editor.propTypes = {
	className: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	name: PropTypes.string,
	label: PropTypes.string,
	toolbarConfig: PropTypes.object,
	disabled: PropTypes.bool,
	focus: PropTypes.string,
};
