import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import { IconWrapper } from '../../../../../../../../../theme';
import { IconEdit } from '../../../../../../../../binder/assets';

const PreviousResultHeader = ({ returnFirstStep }) => {
	const { t } = useTranslation();

	return (
		<div className="content__schema__final__header">
			<h3 className="content__subtitle">{t('result-sheet:popup.result-form.final-step-title')}</h3>
			<div className="button_update_result">
				<IconWrapper
					Component={IconEdit}
					className="button_update_result__icon"
					onClick={returnFirstStep}
				/>
				{t('result-sheet:popup.result-form.update-results-title')}
			</div>
		</div>
	);
};

export default PreviousResultHeader;

PreviousResultHeader.propTypes = {
	returnFirstStep: PropTypes.func.isRequired,
};
