import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import './sheet-edition-action.scss';

import { formatJsonDate, usePopup } from '../../../../../../shared';
import { Button, IconWrapper } from '../../../../../../theme';
import { ReactComponent as ArrowBack } from '../../../../../../theme/assets/img/icon-arrow-back.svg';
import { ReactComponent as ArrowForward } from '../../../../../../theme/assets/img/icon-arrow-forward.svg';
import { ReactComponent as IconPath } from '../../../../../../theme/assets/img/icon-path.svg';
import { ReactComponent as IconSave } from '../../../../../../theme/assets/img/icon-save.svg';
import { ReactComponent as IconSpinner } from '../../../../../../theme/assets/img/icon-spinner.svg';
import { useSheetContentContext } from '../../../../context/sheet-content-context';
import { useSheetModeContext } from '../../../../context/sheet-mode-context';
import { runAnalysis } from '../../../../sheet.services';
import PopupRunAnalysis from '../popup-run-analysis/popup-run-analysis';

/**
 * @param {{
 * 	lastChangeAt: string
 *  binderId: string
 *  refresh: () => void
 * }} props
 * @returns {JSX.Element}
 */
const SheetEditionAction = ({ lastChangeAt, binderId, refresh = () => {} }) => {
	const { t } = useTranslation();
	const { sheetId } = useParams();
	const [isSaving, setIsSaving] = useState(false);
	const saveTimeoutRef = useRef(null);

	const {
		saveSheetContent,
		nodeList,
		nodeHistory,
		nextNodeHistory,
		previousNodeHistory,
		nodeHistoryPointer,
	} = useSheetContentContext();
	const { readOnly } = useSheetModeContext();
	const popupRunAnalysisControl = usePopup();
	const [resultAnalysis, setResultAnalysis] = useState();

	const handleSave = useCallback(async () => {
		if (isSaving) return;
		setIsSaving(true);
		try {
			const response = await saveSheetContent(binderId, sheetId);
			if (!response?.data || response?.data?.success === false) {
				throw new Error('Invalid response data from saveSheetContent');
			}
			refresh(response.data);
		} catch (error) {
			console.error('Save operation failed:', {
				error,
				binderId,
				sheetId,
				responseData: error.response?.data,
				status: error.response?.status,
			});
		} finally {
			setIsSaving(false);
		}
	}, [binderId, refresh, saveSheetContent, sheetId, isSaving]);

	useEffect(() => {
		if (!nodeList?.length || isSaving) {
			console.info('Skipping save:', {
				hasNodes: !!nodeList?.length,
				isSaving,
			});
			return;
		}

		console.info('Setting up save timeout');
		saveTimeoutRef.current = setTimeout(handleSave, 10000);

		return () => {
			if (saveTimeoutRef.current) {
				console.info('Clearing save timeout');
				clearTimeout(saveTimeoutRef.current);
			}
		};
	}, [nodeList, handleSave, isSaving]);

	const handlePreviousButton = () => previousNodeHistory();
	const handleNextButton = () => nextNodeHistory();

	const launchAnalysis = () => {
		runAnalysis(sheetId).then((response) => {
			if (response?.data) {
				setResultAnalysis(response.data);
				popupRunAnalysisControl.show();
			}
		});
	};

	return (
		<div className="sheet-edition__actions">
			<PopupRunAnalysis analysisResult={resultAnalysis} popupControl={popupRunAnalysisControl} />
			<div className="sheet-edition__actions__wrapper">
				{!readOnly && !isSaving && (
					<>
						<span className="sheet-edition__actions__last-save">
							{t('sheet:sheet-details.tabs-panel.actions.last-save')}{' '}
							{formatJsonDate(lastChangeAt, 'HH:mm')}
						</span>
						<Button variant="minimal" type="button" onClick={handleSave} icon={IconSave} />
					</>
				)}
				{!readOnly && isSaving && (
					<>
						<button type="button" className="sheet-edition__actions__save " onClick={handleSave}>
							<IconWrapper
								className="sheet-edition__actions__icon sheet-edition__actions__icon__spinner"
								Component={IconSpinner}
							/>
						</button>
						<span className="sheet-edition__actions__last-save">
							{t('sheet:sheet-details.tabs-panel.actions.saving')}...
						</span>
					</>
				)}
			</div>

			<div className="sheet-edition__actions__wrapper">
				{!readOnly && (
					<>
						<Button
							small
							variant="secondary"
							type="button"
							className="sheet-edition__actions__single-action sheet-edition__actions__single-action--cancel"
							onClick={handlePreviousButton}
							disabled={nodeHistoryPointer < 1}
							icon={ArrowBack}
							iconTooltipContent={t('sheet:sheet-details.tabs-panel.actions.cancel')}
						/>
						<Button
							small
							variant="secondary"
							type="button"
							className="sheet-edition__actions__single-action sheet-edition__actions__single-action--undo"
							onClick={handleNextButton}
							disabled={nodeHistoryPointer >= nodeHistory.length - 1}
							icon={ArrowForward}
							iconTooltipContent={t('sheet:sheet-details.tabs-panel.actions.undo')}
						/>
						<Button
							small
							variant="primary"
							type="button"
							className="sheet-edition__actions__single-action sheet-edition__actions__single-action--path"
							onClick={launchAnalysis}
							icon={IconPath}
							iconTooltipContent={t('sheet:sheet-details.tabs-panel.actions.test-paths')}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default SheetEditionAction;

SheetEditionAction.propTypes = {
	lastChangeAt: PropTypes.string,
	binderId: PropTypes.string,
	refresh: PropTypes.func,
};
