import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
const RailcarType4 = ({ formatType }) => (
	<div className="car-content-type4">
		<div className="raw__type4"></div>
		<div className="little-dark-rectangle" />
		<div className="car-container">
			<div className="car">V1</div>
			<div className="car-join" />
			<div className="car">V2</div>
			<div className="car-join" />
			<div className="car">V3</div>
			<div className="car-join" />
			<div className="car">V4</div>
			<div className="car-join" />
			<div className="car">V5</div>
			{formatType.type === 'fourthLong' && (
				<>
					<div className="car-join" />
					<div className="car">V6</div>
					<div className="car-join" />
					<div className="car">V7</div>
					<div className="car-join" />
					<div className="car">V8</div>
				</>
			)}
		</div>
	</div>
);

export default RailcarType4;

RailcarType4.propTypes = {
	formatType: PropTypes.string.isRequired,
};
