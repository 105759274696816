import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import { Button } from '../../../../../../../theme/index.js';
import InfoPression from '../info-pression/infoPression.js';

const PressionType3 = ({ formData, handleChange, handleValidate }) => {
	const { t } = useTranslation();

	const notCePressions = formData.cePressions.every((item) => item === null);

	return (
		<div className="content__step">
			<div className="content__schema">
				<h3 className="content__subtitle">
					{t('result-sheet:popup.result-form.step-pression-title2')}
				</h3>
				<div className="schema__pressure-box-container">
					{formData.cePressions.map((value, index) => (
						<InfoPression key={index} handleChange={handleChange} index={index} value={value} />
					))}
				</div>
				<div className="schema__base-schema__step1">
					<div className="schema_header_train_type3"></div>
					<div className="schema__checkbox-container">
						{formData.closedXce.map((checked, index) => (
							<div key={index} className="railcar-container-type3">
								<label key={index} className="checkbox-pression">
									<input
										type="checkbox"
										name="closedXce"
										checked={checked}
										onChange={(event) => handleChange(event, index)}
									/>
									<span className="checkbox-custom"></span>
								</label>
							</div>
						))}
					</div>
					<div className="schema_header_train_type3"></div>
				</div>
			</div>
			<div className="content__actions">
				<Button
					variant="primary"
					onClick={handleValidate}
					label={t('result-sheet:popup.result-form.step-pressure-validate')}
					disabled={notCePressions}
					style={{ height: '5vh', fontSize: '20px' }}
				/>
			</div>
		</div>
	);
};

export default PressionType3;
PressionType3.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
};
