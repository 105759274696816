import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from '../../../../../../../theme';
import RedXrFrCheckbox from '../../components/railcar-checkbox/xr-fr/xr-fr-checkbox';
import RailcarType1 from '../../components/railcar-type1/railcar-type1';

const RedXrFrType1 = ({ formData, handleChange, handleValidate }) => {
	const { t } = useTranslation();

	return (
		<div className="content-container">
			<RailcarType1 />
			<h3 className="content__subtitle">{t('result-sheet:popup.result-form.step-red-xr-fr')}</h3>
			<div className="red-railcar-content">
				{formData.redXrFr.map((checked, index) => (
					<RedXrFrCheckbox
						checked={checked}
						redRailCarChecked={formData.redRailcars[index]}
						handleChange={handleChange}
						index={index}
						key={index}
					/>
				))}
			</div>
			<div className="content__actions">
				<Button
					variant="primary"
					onClick={handleValidate}
					label={t('result-sheet:popup.result-form.validate')}
				/>
			</div>
		</div>
	);
};

export default RedXrFrType1;

RedXrFrType1.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
};
