import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import TotalCount from '../components/total-count/total-count';

import PreviousResultType1 from './previous-result/previous-result';
import ResumeCheckboxes from './resume-checkboxes/resume-checkboxes';

const FinalStepType1 = ({ formData, handleChange, returnFirstStep, setValidCheckbox }) => {
	const { t } = useTranslation();
	const [resumeCheckboxes, setResumeCheckboxes] = useState(Array(5).fill(false));

	const updateNonOrangeBogies = formData.nonOrangeBogies.reduce((acc, curr, index) => {
		if (index % 2 === 0) {
			acc.push(curr);
		} else {
			acc[acc.length - 1] = acc[acc.length - 1] || curr;
		}
		return acc;
	}, []);

	const resultCheck = updateNonOrangeBogies.map((_value, index) => ({
		redRailCarTrain: formData.redRailcars[index],
		redXrFrCarTrain: formData.redXrFr[index],
		nonOrangeBogiesCarTrain: updateNonOrangeBogies[index],
	}));
	const updatedResultCheck = resultCheck.map((item) => ({
		...item,
		checked: item.redXrFrCarTrain || item.nonOrangeBogiesCarTrain || item.redRailCarTrain,
	}));
	const countChecked = updatedResultCheck.filter((item) => item.checked).length;

	useEffect(() => {
		setValidCheckbox(countChecked);
	}, [countChecked, setValidCheckbox]);

	return (
		<>
			<PreviousResultType1 formData={formData} returnFirstStep={returnFirstStep} />
			<ResumeCheckboxes
				resumeCheckboxes={resumeCheckboxes}
				setResumeCheckboxes={setResumeCheckboxes}
				updatedResultCheck={updatedResultCheck}
			/>
			<TotalCount
				checkCounter={formData.checkCounter}
				handleChange={handleChange}
				subtitle={t('result-sheet:popup.result-form.final-step.total-count-type1')}
				TotalCountText={t('result-sheet:popup.result-form.final-step.total-count-railcar')}
			/>
		</>
	);
};

export default FinalStepType1;

FinalStepType1.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	returnFirstStep: PropTypes.func.isRequired,
	setValidCheckbox: PropTypes.func.isRequired,
};
