import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';

import InfoPression from '../../pression/info-pression/infoPression';

const RailcarType5 = ({ cePressions, closedXce, handleChange, isFinalStep }) => {
	const { t } = useTranslation();
	const middleClosedXce = closedXce ? closedXce.slice(1, 9) : Array(8).fill(null);
	const getInputStyle = (checked) => {
		return {
			width: '15px',
			height: '15px',
			accentColor: isFinalStep ? '#5d5c6d' : '#0022c0',
			cursor: isFinalStep ? 'not-allowed' : 'default',
			opacity: isFinalStep & !checked ? 0.5 : 1,
		};
	};

	return (
		<>
			<div className="railcar-type5-container">
				<div className="raw__type4" />
				<div className="railcar-type5-content">
					<div className="railcar-type5 pression justify-content-start">
						{cePressions && (
							<div className="info-pression">
								<InfoPression
									disabled={isFinalStep}
									handleChange={handleChange}
									height="3em"
									index={0}
									value={cePressions[0]}
								/>
							</div>
						)}
						<div className="half-circle right">
							{closedXce && (
								<input
									checked={closedXce[0]}
									name="closedXce"
									onChange={(event) => handleChange(event, 0)}
									type="checkbox"
									style={getInputStyle(closedXce[0])}
									className={isFinalStep ? 'checkbox-disabled' : ''}
								/>
							)}
						</div>
					</div>
					<div className="join-railcar" />
					{middleClosedXce.map((closedXce, index) => {
						return index % 2 === 0 ? (
							<Fragment key={index}>
								<div className="railcar-type5">
									<div className="half-circle right">
										{closedXce !== null && (
											<input
												checked={middleClosedXce[index + 1]}
												name="closedXce"
												onChange={(event) => handleChange(event, index + 2)}
												type="checkbox"
												style={getInputStyle(middleClosedXce[index + 1])}
												className={isFinalStep ? 'checkbox-disabled' : ''}
											/>
										)}
									</div>
									<div className="half-circle left">
										{closedXce !== null && (
											<input
												checked={middleClosedXce[index]}
												name="closedXce"
												onChange={(event) => handleChange(event, index + 1)}
												type="checkbox"
												style={getInputStyle(middleClosedXce[index])}
												className={isFinalStep ? 'checkbox-disabled' : ''}
											/>
										)}
									</div>
								</div>
								<div className="join-railcar" />
							</Fragment>
						) : null;
					})}
					<div className="railcar-type5 pression justify-content-end">
						<div className="half-circle left">
							{closedXce && (
								<input
									checked={closedXce[9]}
									name="closedXce"
									onChange={(event) => handleChange(event, 9)}
									type="checkbox"
									style={getInputStyle(closedXce[9])}
									className={isFinalStep ? 'checkbox-disabled' : ''}
								/>
							)}
						</div>
						{cePressions && (
							<div className="info-pression">
								<InfoPression
									disabled={isFinalStep}
									handleChange={handleChange}
									index={1}
									value={cePressions[1]}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			{!isFinalStep && <h3 className="avant">{t('result-sheet:popup.result-form.before')}</h3>}
		</>
	);
};

export default RailcarType5;

RailcarType5.propTypes = {
	handleChange: PropTypes.func,
	cePressions: PropTypes.array,
	closedXce: PropTypes.array,
	isFinalStep: PropTypes.bool,
};
