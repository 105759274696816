import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import '../styles.scss';

import RailcarXrType4 from '../../../components/railcar-type4/railcar-xr-type4';
import PreviousResultHeader from '../../components/previous-result-header/header/header';

const PreviousResult = ({ pressionValues, formData, formatType, returnFirstStep }) => {
	const { t } = useTranslation();
	const finalStep = 'final__step';
	return (
		<div className="content__schema__final">
			<PreviousResultHeader returnFirstStep={returnFirstStep} />

			<div className="schema__base-schema__final_pression">
				{pressionValues.map((pression, index) => (
					<div key={index} className="content__result_pression">
						{t('result-sheet:popup.result-form.title-step-pression')}
						<div className="result_pression">{`${pression} Kpa`}</div>
					</div>
				))}
			</div>
			<div className="state-rail-card">
				<h3 className="content__subtitle_final_step_type4">
					{t('result-sheet:popup.result-form.final-step.air-car-state')}
				</h3>
				<div className="schema__checkbox-container-step-final-type4">
					{formData.redRailcars.map((checked, index) => (
						<div key={index} className={`railcar-container-steps-type4 `}>
							<label className="checkbox-final-step-type4">
								<input type="checkbox" name="redRailcars" checked={checked} readOnly />
								<span
									className="checkbox-custom-final-step-type4"
									style={checked ? { backgroundColor: 'grey', opacity: 0.5 } : { opacity: 1 }}
								></span>
							</label>
						</div>
					))}
				</div>
			</div>
			<RailcarXrType4 steps={finalStep} formatType={formatType} />
			<div className="state-bogie-container">
				<h3 className="content__subtitle_final_step_type4">
					{t('result-sheet:popup.result-form.final-step.bogies-state')}
				</h3>
				<div className="state-bogie-checkbox-container">
					<div className="schema__checkbox-container-step-bogies-final-type4">
						{formData.redXrFr.map((checked, index) => (
							<div key={index}>
								<label className="checkbox-final-step-type4">
									<input type="checkbox" name="redXrFr" checked={checked} readOnly />
									<span
										className="checkbox-custom-final-step-type4"
										style={checked ? { backgroundColor: 'grey', opacity: 0.5 } : { opacity: 1 }}
									></span>
								</label>
							</div>
						))}
					</div>
					<div className="schema__checkbox-container-step-bogies-final-type4">
						{formData.nonOrangeBogies.map((checked, index) => (
							<div key={index}>
								<label className="checkbox-final-step-type4">
									<input type="checkbox" name="nonOrangeBogies" checked={checked} readOnly />
									<span
										className="checkbox-custom-final-step-type4"
										style={checked ? { backgroundColor: 'grey', opacity: 0.5 } : { opacity: 1 }}
									></span>
								</label>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="schema__base-schema__final_check"></div>
		</div>
	);
};

export default PreviousResult;

PreviousResult.propTypes = {
	formData: PropTypes.array.isRequired,
	pressionValues: PropTypes.array.isRequired,
	returnFirstStep: PropTypes.number.isRequired,
	formatType: PropTypes.string.isRequired,
};
