import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from '../../../../../../../theme';
import OrangeBogieCheckbox from '../../components/orange-bogie-checkbox/orange-bogie-checkbox';
import RailcarType4 from '../../components/railcar-type4/railcar-type4';
import RailcarXrType4 from '../../components/railcar-type4/railcar-xr-type4';

const NonOrangeBogiesType4 = ({ handleValidate, formData, handleChange, formatType }) => {
	const { t } = useTranslation();
	const type = 'type4';
	const nonOrangeBogies = 'nonOrangeBogies';
	const steps = 'steps';

	return (
		<div className="content-container">
			<RailcarType4 formatType={formatType} />
			<h3 className="content__subtitle">
				{t('result-sheet:popup.result-form.step-nonOrangeBogies-type4')}
			</h3>
			<div className={`checkbox-container-steps-type4 ${formatType.type}`}>
				<RailcarXrType4 steps={steps} formatType={formatType} />
				<div className={`orange-bogie-checkbox-type4 ${formatType.type}`}>
					{formData.nonOrangeBogies.map((checked, index) => {
						return (
							<OrangeBogieCheckbox
								key={index}
								checked={checked}
								handleChange={handleChange}
								index={index}
								type={type}
								nonOrangeBogies={nonOrangeBogies}
							/>
						);
					})}
				</div>
			</div>

			<div className="content__actions">
				<Button
					variant="primary"
					onClick={handleValidate}
					label={t('result-sheet:popup.result-form.validate-to-final-step')}
					style={{ height: '5vh', fontSize: '20px' }}
				/>
			</div>
		</div>
	);
};

export default NonOrangeBogiesType4;

NonOrangeBogiesType4.propTypes = {
	formData: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleValidate: PropTypes.func.isRequired,
	formatType: PropTypes.string.isRequired,
};
