import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
const PairRoundedCheckbox = ({ handleChange, checkedArray, index, name, width = '12%' }) => {
	return (
		<div className="powered-bogie-container" style={{ width: width, height: '6vh' }}>
			<div className="powered-bogie-checkbox-container border-right">
				<input
					type="checkbox"
					name={name}
					checked={checkedArray[index]}
					onChange={(event) => handleChange(event, index)}
					style={{ accentColor: '#0022c0', width: '25px', height: '25px' }}
				/>
			</div>
			<div className="powered-bogie-checkbox-container">
				<input
					type="checkbox"
					name={name}
					checked={checkedArray[index + 1]}
					onChange={(event) => handleChange(event, index + 1)}
					style={{ accentColor: '#0022c0', width: '25px', height: '25px' }}
				/>
			</div>
		</div>
	);
};

export default PairRoundedCheckbox;

PairRoundedCheckbox.propTypes = {
	handleChange: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	checkedArray: PropTypes.arrayOf(PropTypes.bool).isRequired,
	width: PropTypes.string,
};
