import React from 'react';
import PropTypes from 'prop-types';

import '../styles.scss';

const RedRailCarCheckbox = ({ checked, index, handleChange }) => {
	return (
		<div className="container">
			<div className={`top-bar ${checked ? 'border-secondary' : 'border-primary'}`}>
				<input
					checked={checked}
					name="redRailcars"
					onChange={(event) => handleChange(event, index)}
					type="checkbox"
					style={{ accentColor: '#0022c0', width: '20px' }}
				/>
			</div>
			<div
				className={`middle-bar border-top-grey ${checked ? 'border-side-secondary' : 'border-side-primary'}`}
			/>
			<div className="middle-bar" />
			<div className="circle border-grey">
				<div className="square" />
			</div>
		</div>
	);
};

export default RedRailCarCheckbox;

RedRailCarCheckbox.propTypes = {
	checked: PropTypes.bool.isRequired,
	index: PropTypes.number.isRequired,
	handleChange: PropTypes.func.isRequired,
};
