import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const PreviousResultCheckboxes = ({ subtitle, checkedArray }) => {
	return (
		<div className="previous-result-checkboxes-container">
			<div className="previous-result-bogie-subtitle">
				<h3>{subtitle}</h3>
			</div>
			<div className="previous-result-checkbox-container">
				{checkedArray.map((__checked, index) => {
					return index % 2 === 0 ? (
						<div
							className={`previous-result-checkbox ${index + 1 !== checkedArray.length - 1 ? 'border-dashed-right' : ''}`}
							key={`powered-${index}`}
						>
							<input type="checkbox" checked={checkedArray[index]} disabled={true} />
							<input type="checkbox" checked={checkedArray[index + 1]} disabled={true} />
						</div>
					) : null;
				})}
			</div>
		</div>
	);
};

export default PreviousResultCheckboxes;

PreviousResultCheckboxes.propTypes = {
	subtitle: PropTypes.string.isRequired,
	checkedArray: PropTypes.arrayOf(PropTypes.bool).isRequired,
};
