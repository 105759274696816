import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './styles.scss';
const PreviousResultPression = ({ pression }) => {
	const { t } = useTranslation();
	return (
		<div className="content__result_pression">
			{t('result-sheet:popup.result-form.title-step-pression')}
			<div className="result_pression">{`${pression} KPa`}</div>
		</div>
	);
};

export default PreviousResultPression;

PreviousResultPression.propTypes = {
	pression: PropTypes.number.isRequired,
};
